/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import SkillLevel from './SkillLevel';
import SkillPlatformDto from './SkillPlatformDto';

/**
 * The CandidateSkillPlatformDto model module.
 * @module model/CandidateSkillPlatformDto
 * @version v3.17(PROD)
 */
export default class CandidateSkillPlatformDto {
  /**
   * Constructs a new <code>CandidateSkillPlatformDto</code>.
   * @alias module:model/CandidateSkillPlatformDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateSkillPlatformDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateSkillPlatformDto} obj Optional instance to populate.
   * @return {module:model/CandidateSkillPlatformDto} The populated <code>CandidateSkillPlatformDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateSkillPlatformDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('skillLevel'))
        obj.skillLevel = SkillLevel.constructFromObject(data['skillLevel']);
      if (data.hasOwnProperty('experienceInMonths'))
        obj.experienceInMonths = ApiClient.convertToType(data['experienceInMonths'], 'Number');
      if (data.hasOwnProperty('weightByCandidate'))
        obj.weightByCandidate = ApiClient.convertToType(data['weightByCandidate'], 'String');
      if (data.hasOwnProperty('occurrence'))
        obj.occurrence = ApiClient.convertToType(data['occurrence'], 'Number');
      if (data.hasOwnProperty('isHotSkill'))
        obj.isHotSkill = ApiClient.convertToType(data['isHotSkill'], 'Boolean');
      if (data.hasOwnProperty('isSelfVerified'))
        obj.isSelfVerified = ApiClient.convertToType(data['isSelfVerified'], 'Boolean');
      if (data.hasOwnProperty('candidateSkillId'))
        obj.candidateSkillId = ApiClient.convertToType(data['candidateSkillId'], 'Number');
      if (data.hasOwnProperty('skillPlatformId'))
        obj.skillPlatformId = ApiClient.convertToType(data['skillPlatformId'], 'Number');
      if (data.hasOwnProperty('skillPlatform'))
        obj.skillPlatform = SkillPlatformDto.constructFromObject(data['skillPlatform']);
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CandidateSkillPlatformDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CandidateSkillPlatformDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CandidateSkillPlatformDto.prototype.modifiedBy = undefined;

/**
 * @member {String} name
 */
CandidateSkillPlatformDto.prototype.name = undefined;

/**
 * @member {module:model/SkillLevel} skillLevel
 */
CandidateSkillPlatformDto.prototype.skillLevel = undefined;

/**
 * @member {Number} experienceInMonths
 */
CandidateSkillPlatformDto.prototype.experienceInMonths = undefined;

/**
 * @member {String} weightByCandidate
 */
CandidateSkillPlatformDto.prototype.weightByCandidate = undefined;

/**
 * @member {Number} occurrence
 */
CandidateSkillPlatformDto.prototype.occurrence = undefined;

/**
 * @member {Boolean} isHotSkill
 */
CandidateSkillPlatformDto.prototype.isHotSkill = undefined;

/**
 * @member {Boolean} isSelfVerified
 */
CandidateSkillPlatformDto.prototype.isSelfVerified = undefined;

/**
 * @member {Number} candidateSkillId
 */
CandidateSkillPlatformDto.prototype.candidateSkillId = undefined;

/**
 * @member {Number} skillPlatformId
 */
CandidateSkillPlatformDto.prototype.skillPlatformId = undefined;

/**
 * @member {module:model/SkillPlatformDto} skillPlatform
 */
CandidateSkillPlatformDto.prototype.skillPlatform = undefined;

/**
 * @member {Number} candidateId
 */
CandidateSkillPlatformDto.prototype.candidateId = undefined;

