/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import CandidateConfigurationCompactDtoApiResponse from '../model/CandidateConfigurationCompactDtoApiResponse';
import CandidateConfigurationCompactDtoIEnumerableApiResponse from '../model/CandidateConfigurationCompactDtoIEnumerableApiResponse';
import CandidateConfigurationCreateDto from '../model/CandidateConfigurationCreateDto';
import CandidateConfigurationDtoApiResponse from '../model/CandidateConfigurationDtoApiResponse';
import CandidateConfigurationEditDto from '../model/CandidateConfigurationEditDto';

/**
* CandidateConfiguration service.
* @module api/CandidateConfigurationApi
* @version v3.17(PROD)
*/
export default class CandidateConfigurationApi {

    /**
    * Constructs a new CandidateConfigurationApi. 
    * @alias module:api/CandidateConfigurationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiCandidateConfigurationGet operation.
     * @callback moduleapi/CandidateConfigurationApi~apiCandidateConfigurationGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateConfigurationCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CandidateConfigurationApi~apiCandidateConfigurationGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateConfigurationGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateConfigurationCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateConfiguration', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateConfigurationGetByGuidGuidGet operation.
     * @callback moduleapi/CandidateConfigurationApi~apiCandidateConfigurationGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateConfigurationDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateConfigurationApi~apiCandidateConfigurationGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateConfigurationGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateConfigurationGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateConfigurationDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateConfiguration/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateConfigurationGetConfigurationByCandidateIdCandidateIdGet operation.
     * @callback moduleapi/CandidateConfigurationApi~apiCandidateConfigurationGetConfigurationByCandidateIdCandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateConfigurationCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {module:api/CandidateConfigurationApi~apiCandidateConfigurationGetConfigurationByCandidateIdCandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateConfigurationGetConfigurationByCandidateIdCandidateIdGet(candidateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateConfigurationGetConfigurationByCandidateIdCandidateIdGet");
      }

      let pathParams = {
        'CandidateId': candidateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateConfigurationCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateConfiguration/GetConfigurationByCandidateId/{CandidateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateConfigurationIdDelete operation.
     * @callback moduleapi/CandidateConfigurationApi~apiCandidateConfigurationIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateConfigurationCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateConfigurationApi~apiCandidateConfigurationIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateConfigurationIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateConfigurationIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateConfigurationCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateConfiguration/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateConfigurationIdGet operation.
     * @callback moduleapi/CandidateConfigurationApi~apiCandidateConfigurationIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateConfigurationDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateConfigurationApi~apiCandidateConfigurationIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateConfigurationIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateConfigurationIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateConfigurationDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateConfiguration/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateConfigurationPost operation.
     * @callback moduleapi/CandidateConfigurationApi~apiCandidateConfigurationPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateConfigurationDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateConfigurationCreateDto} opts.body 
     * @param {module:api/CandidateConfigurationApi~apiCandidateConfigurationPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateConfigurationPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateConfigurationDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateConfiguration', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateConfigurationPut operation.
     * @callback moduleapi/CandidateConfigurationApi~apiCandidateConfigurationPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateConfigurationDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateConfigurationEditDto} opts.body 
     * @param {module:api/CandidateConfigurationApi~apiCandidateConfigurationPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateConfigurationPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateConfigurationDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateConfiguration', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}