/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import RSAdditionalDetails from './RSAdditionalDetails';
import RSCandidateAddress from './RSCandidateAddress';
import RSCandidateCertificate from './RSCandidateCertificate';
import RSCandidatePersonalInfoDto from './RSCandidatePersonalInfoDto';
import RSCandidateTrait from './RSCandidateTrait';
import RSExperienceTimeLine from './RSExperienceTimeLine';
import RSExpertise from './RSExpertise';
import RSNormalizationStatus from './RSNormalizationStatus';
import RSProfileCompletion from './RSProfileCompletion';
import RSProfileHeadLine from './RSProfileHeadLine';
import RSProfileImage from './RSProfileImage';
import RSQualificationTimeLine from './RSQualificationTimeLine';
import RSSocialMediaWebsite from './RSSocialMediaWebsite';
import RSSoftSkill from './RSSoftSkill';
import RSTechnicalSkill from './RSTechnicalSkill';
import RSTimeLine from './RSTimeLine';
import RSWebsite from './RSWebsite';

/**
 * The CandidateProfileViewDto model module.
 * @module model/CandidateProfileViewDto
 * @version v3.17(PROD)
 */
export default class CandidateProfileViewDto {
  /**
   * Constructs a new <code>CandidateProfileViewDto</code>.
   * @alias module:model/CandidateProfileViewDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateProfileViewDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateProfileViewDto} obj Optional instance to populate.
   * @return {module:model/CandidateProfileViewDto} The populated <code>CandidateProfileViewDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateProfileViewDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('normalizationStatus'))
        obj.normalizationStatus = RSNormalizationStatus.constructFromObject(data['normalizationStatus']);
      if (data.hasOwnProperty('profileCompletion'))
        obj.profileCompletion = RSProfileCompletion.constructFromObject(data['profileCompletion']);
      if (data.hasOwnProperty('profileImage'))
        obj.profileImage = RSProfileImage.constructFromObject(data['profileImage']);
      if (data.hasOwnProperty('profileHeadLine'))
        obj.profileHeadLine = RSProfileHeadLine.constructFromObject(data['profileHeadLine']);
      if (data.hasOwnProperty('candidateAddress'))
        obj.candidateAddress = RSCandidateAddress.constructFromObject(data['candidateAddress']);
      if (data.hasOwnProperty('candidatePersonalData'))
        obj.candidatePersonalData = RSCandidatePersonalInfoDto.constructFromObject(data['candidatePersonalData']);
      if (data.hasOwnProperty('socialMediaWebsites'))
        obj.socialMediaWebsites = ApiClient.convertToType(data['socialMediaWebsites'], [RSSocialMediaWebsite]);
      if (data.hasOwnProperty('websites'))
        obj.websites = ApiClient.convertToType(data['websites'], [RSWebsite]);
      if (data.hasOwnProperty('expertise'))
        obj.expertise = ApiClient.convertToType(data['expertise'], [RSExpertise]);
      if (data.hasOwnProperty('softSkill'))
        obj.softSkill = ApiClient.convertToType(data['softSkill'], [RSSoftSkill]);
      if (data.hasOwnProperty('technicalSkill'))
        obj.technicalSkill = ApiClient.convertToType(data['technicalSkill'], [RSTechnicalSkill]);
      if (data.hasOwnProperty('additionalDetails'))
        obj.additionalDetails = RSAdditionalDetails.constructFromObject(data['additionalDetails']);
      if (data.hasOwnProperty('timeLine'))
        obj.timeLine = ApiClient.convertToType(data['timeLine'], [RSTimeLine]);
      if (data.hasOwnProperty('experienceTimeLine'))
        obj.experienceTimeLine = ApiClient.convertToType(data['experienceTimeLine'], [RSExperienceTimeLine]);
      if (data.hasOwnProperty('qualificationTimeLine'))
        obj.qualificationTimeLine = ApiClient.convertToType(data['qualificationTimeLine'], [RSQualificationTimeLine]);
      if (data.hasOwnProperty('certificates'))
        obj.certificates = ApiClient.convertToType(data['certificates'], [RSCandidateCertificate]);
      if (data.hasOwnProperty('traits'))
        obj.traits = ApiClient.convertToType(data['traits'], [RSCandidateTrait]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CandidateProfileViewDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CandidateProfileViewDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CandidateProfileViewDto.prototype.modifiedBy = undefined;

/**
 * @member {module:model/RSNormalizationStatus} normalizationStatus
 */
CandidateProfileViewDto.prototype.normalizationStatus = undefined;

/**
 * @member {module:model/RSProfileCompletion} profileCompletion
 */
CandidateProfileViewDto.prototype.profileCompletion = undefined;

/**
 * @member {module:model/RSProfileImage} profileImage
 */
CandidateProfileViewDto.prototype.profileImage = undefined;

/**
 * @member {module:model/RSProfileHeadLine} profileHeadLine
 */
CandidateProfileViewDto.prototype.profileHeadLine = undefined;

/**
 * @member {module:model/RSCandidateAddress} candidateAddress
 */
CandidateProfileViewDto.prototype.candidateAddress = undefined;

/**
 * @member {module:model/RSCandidatePersonalInfoDto} candidatePersonalData
 */
CandidateProfileViewDto.prototype.candidatePersonalData = undefined;

/**
 * @member {Array.<module:model/RSSocialMediaWebsite>} socialMediaWebsites
 */
CandidateProfileViewDto.prototype.socialMediaWebsites = undefined;

/**
 * @member {Array.<module:model/RSWebsite>} websites
 */
CandidateProfileViewDto.prototype.websites = undefined;

/**
 * @member {Array.<module:model/RSExpertise>} expertise
 */
CandidateProfileViewDto.prototype.expertise = undefined;

/**
 * @member {Array.<module:model/RSSoftSkill>} softSkill
 */
CandidateProfileViewDto.prototype.softSkill = undefined;

/**
 * @member {Array.<module:model/RSTechnicalSkill>} technicalSkill
 */
CandidateProfileViewDto.prototype.technicalSkill = undefined;

/**
 * @member {module:model/RSAdditionalDetails} additionalDetails
 */
CandidateProfileViewDto.prototype.additionalDetails = undefined;

/**
 * @member {Array.<module:model/RSTimeLine>} timeLine
 */
CandidateProfileViewDto.prototype.timeLine = undefined;

/**
 * @member {Array.<module:model/RSExperienceTimeLine>} experienceTimeLine
 */
CandidateProfileViewDto.prototype.experienceTimeLine = undefined;

/**
 * @member {Array.<module:model/RSQualificationTimeLine>} qualificationTimeLine
 */
CandidateProfileViewDto.prototype.qualificationTimeLine = undefined;

/**
 * @member {Array.<module:model/RSCandidateCertificate>} certificates
 */
CandidateProfileViewDto.prototype.certificates = undefined;

/**
 * @member {Array.<module:model/RSCandidateTrait>} traits
 */
CandidateProfileViewDto.prototype.traits = undefined;

