/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CourseDto from './CourseDto';
import DocumentDto from './DocumentDto';

/**
 * The ContentDto model module.
 * @module model/ContentDto
 * @version v3.17(PROD)
 */
export default class ContentDto {
  /**
   * Constructs a new <code>ContentDto</code>.
   * @alias module:model/ContentDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ContentDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ContentDto} obj Optional instance to populate.
   * @return {module:model/ContentDto} The populated <code>ContentDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ContentDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('documentId'))
        obj.documentId = ApiClient.convertToType(data['documentId'], 'Number');
      if (data.hasOwnProperty('document'))
        obj.document = DocumentDto.constructFromObject(data['document']);
      if (data.hasOwnProperty('courseId'))
        obj.courseId = ApiClient.convertToType(data['courseId'], 'Number');
      if (data.hasOwnProperty('course'))
        obj.course = CourseDto.constructFromObject(data['course']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ContentDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
ContentDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
ContentDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
ContentDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
ContentDto.prototype.name = undefined;

/**
 * @member {String} type
 */
ContentDto.prototype.type = undefined;

/**
 * @member {String} description
 */
ContentDto.prototype.description = undefined;

/**
 * @member {Number} documentId
 */
ContentDto.prototype.documentId = undefined;

/**
 * @member {module:model/DocumentDto} document
 */
ContentDto.prototype.document = undefined;

/**
 * @member {Number} courseId
 */
ContentDto.prototype.courseId = undefined;

/**
 * @member {module:model/CourseDto} course
 */
ContentDto.prototype.course = undefined;

