/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ParserType from './ParserType';

/**
 * The ParserDocumentCompactDto model module.
 * @module model/ParserDocumentCompactDto
 * @version v3.17(PROD)
 */
export default class ParserDocumentCompactDto {
  /**
   * Constructs a new <code>ParserDocumentCompactDto</code>.
   * @alias module:model/ParserDocumentCompactDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ParserDocumentCompactDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ParserDocumentCompactDto} obj Optional instance to populate.
   * @return {module:model/ParserDocumentCompactDto} The populated <code>ParserDocumentCompactDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ParserDocumentCompactDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('isParsed'))
        obj.isParsed = ApiClient.convertToType(data['isParsed'], 'Boolean');
      if (data.hasOwnProperty('parserType'))
        obj.parserType = ParserType.constructFromObject(data['parserType']);
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('retryLimit'))
        obj.retryLimit = ApiClient.convertToType(data['retryLimit'], 'Number');
      if (data.hasOwnProperty('retryDelayInSeconds'))
        obj.retryDelayInSeconds = ApiClient.convertToType(data['retryDelayInSeconds'], 'Number');
      if (data.hasOwnProperty('mongoDBObjectId'))
        obj.mongoDBObjectId = ApiClient.convertToType(data['mongoDBObjectId'], 'String');
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
      if (data.hasOwnProperty('documentId'))
        obj.documentId = ApiClient.convertToType(data['documentId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ParserDocumentCompactDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
ParserDocumentCompactDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
ParserDocumentCompactDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
ParserDocumentCompactDto.prototype.uniqueGuid = undefined;

/**
 * @member {Boolean} isParsed
 */
ParserDocumentCompactDto.prototype.isParsed = undefined;

/**
 * @member {module:model/ParserType} parserType
 */
ParserDocumentCompactDto.prototype.parserType = undefined;

/**
 * @member {String} status
 */
ParserDocumentCompactDto.prototype.status = undefined;

/**
 * @member {Number} retryLimit
 */
ParserDocumentCompactDto.prototype.retryLimit = undefined;

/**
 * @member {Number} retryDelayInSeconds
 */
ParserDocumentCompactDto.prototype.retryDelayInSeconds = undefined;

/**
 * @member {String} mongoDBObjectId
 */
ParserDocumentCompactDto.prototype.mongoDBObjectId = undefined;

/**
 * @member {Number} candidateId
 */
ParserDocumentCompactDto.prototype.candidateId = undefined;

/**
 * @member {Number} documentId
 */
ParserDocumentCompactDto.prototype.documentId = undefined;

