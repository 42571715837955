/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import DegreeAliasCompactDtoApiResponse from '../model/DegreeAliasCompactDtoApiResponse';
import DegreeAliasCompactDtoIEnumerableApiResponse from '../model/DegreeAliasCompactDtoIEnumerableApiResponse';
import DegreeAliasCompactDtoPagedListApiResponse from '../model/DegreeAliasCompactDtoPagedListApiResponse';
import DegreeAliasCreateDto from '../model/DegreeAliasCreateDto';
import DegreeAliasDtoApiResponse from '../model/DegreeAliasDtoApiResponse';
import DegreeAliasEditDto from '../model/DegreeAliasEditDto';

/**
* DegreeAlias service.
* @module api/DegreeAliasApi
* @version v3.17(PROD)
*/
export default class DegreeAliasApi {

    /**
    * Constructs a new DegreeAliasApi. 
    * @alias module:api/DegreeAliasApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiDegreeAliasGet operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/DegreeAliasApi~apiDegreeAliasGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiDegreeAliasGetAllByEntityIdEntityIdGet operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasGetAllByEntityIdEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {module:api/DegreeAliasApi~apiDegreeAliasGetAllByEntityIdEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasGetAllByEntityIdEntityIdGet(entityId, callback) {
      
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiDegreeAliasGetAllByEntityIdEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias/GetAllByEntityId/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiDegreeAliasGetAllByPageGet operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/DegreeAliasApi~apiDegreeAliasGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiDegreeAliasGetByGuidGuidGet operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/DegreeAliasApi~apiDegreeAliasGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiDegreeAliasGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiDegreeAliasIdDelete operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/DegreeAliasApi~apiDegreeAliasIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiDegreeAliasIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiDegreeAliasIdGet operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/DegreeAliasApi~apiDegreeAliasIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiDegreeAliasIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiDegreeAliasPost operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/DegreeAliasCreateDto} opts.body 
     * @param {module:api/DegreeAliasApi~apiDegreeAliasPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiDegreeAliasPut operation.
     * @callback moduleapi/DegreeAliasApi~apiDegreeAliasPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DegreeAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/DegreeAliasEditDto} opts.body 
     * @param {module:api/DegreeAliasApi~apiDegreeAliasPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDegreeAliasPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DegreeAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/DegreeAlias', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}