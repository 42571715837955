/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import DocumentDto from './DocumentDto';

/**
 * The CandidateResumeDto model module.
 * @module model/CandidateResumeDto
 * @version v3.17(PROD)
 */
export default class CandidateResumeDto {
  /**
   * Constructs a new <code>CandidateResumeDto</code>.
   * @alias module:model/CandidateResumeDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateResumeDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateResumeDto} obj Optional instance to populate.
   * @return {module:model/CandidateResumeDto} The populated <code>CandidateResumeDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateResumeDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('resumeId'))
        obj.resumeId = ApiClient.convertToType(data['resumeId'], 'Number');
      if (data.hasOwnProperty('resume'))
        obj.resume = DocumentDto.constructFromObject(data['resume']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CandidateResumeDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CandidateResumeDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CandidateResumeDto.prototype.modifiedBy = undefined;

/**
 * @member {Number} resumeId
 */
CandidateResumeDto.prototype.resumeId = undefined;

/**
 * @member {module:model/DocumentDto} resume
 */
CandidateResumeDto.prototype.resume = undefined;

