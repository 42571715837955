/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CandidateAndLanguageDto from './CandidateAndLanguageDto';
import GenderType from './GenderType';

/**
 * The CandidateOtherPersonalInfoDto model module.
 * @module model/CandidateOtherPersonalInfoDto
 * @version v3.17(PROD)
 */
export default class CandidateOtherPersonalInfoDto {
  /**
   * Constructs a new <code>CandidateOtherPersonalInfoDto</code>.
   * @alias module:model/CandidateOtherPersonalInfoDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateOtherPersonalInfoDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateOtherPersonalInfoDto} obj Optional instance to populate.
   * @return {module:model/CandidateOtherPersonalInfoDto} The populated <code>CandidateOtherPersonalInfoDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateOtherPersonalInfoDto();
      if (data.hasOwnProperty('dateOfBirth'))
        obj.dateOfBirth = ApiClient.convertToType(data['dateOfBirth'], 'Date');
      if (data.hasOwnProperty('gender'))
        obj.gender = GenderType.constructFromObject(data['gender']);
      if (data.hasOwnProperty('bloodGroup'))
        obj.bloodGroup = ApiClient.convertToType(data['bloodGroup'], 'String');
      if (data.hasOwnProperty('fatherName'))
        obj.fatherName = ApiClient.convertToType(data['fatherName'], 'String');
      if (data.hasOwnProperty('motherName'))
        obj.motherName = ApiClient.convertToType(data['motherName'], 'String');
      if (data.hasOwnProperty('maritalStatus'))
        obj.maritalStatus = ApiClient.convertToType(data['maritalStatus'], 'String');
      if (data.hasOwnProperty('candidateLanguages'))
        obj.candidateLanguages = ApiClient.convertToType(data['candidateLanguages'], [CandidateAndLanguageDto]);
    }
    return obj;
  }
}

/**
 * @member {Date} dateOfBirth
 */
CandidateOtherPersonalInfoDto.prototype.dateOfBirth = undefined;

/**
 * @member {module:model/GenderType} gender
 */
CandidateOtherPersonalInfoDto.prototype.gender = undefined;

/**
 * @member {String} bloodGroup
 */
CandidateOtherPersonalInfoDto.prototype.bloodGroup = undefined;

/**
 * @member {String} fatherName
 */
CandidateOtherPersonalInfoDto.prototype.fatherName = undefined;

/**
 * @member {String} motherName
 */
CandidateOtherPersonalInfoDto.prototype.motherName = undefined;

/**
 * @member {String} maritalStatus
 */
CandidateOtherPersonalInfoDto.prototype.maritalStatus = undefined;

/**
 * @member {Array.<module:model/CandidateAndLanguageDto>} candidateLanguages
 */
CandidateOtherPersonalInfoDto.prototype.candidateLanguages = undefined;

