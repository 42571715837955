/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The TaggedCandidates model module.
 * @module model/TaggedCandidates
 * @version v3.17(PROD)
 */
export default class TaggedCandidates {
  /**
   * Constructs a new <code>TaggedCandidates</code>.
   * @alias module:model/TaggedCandidates
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>TaggedCandidates</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TaggedCandidates} obj Optional instance to populate.
   * @return {module:model/TaggedCandidates} The populated <code>TaggedCandidates</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TaggedCandidates();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('userId'))
        obj.userId = ApiClient.convertToType(data['userId'], 'Number');
      if (data.hasOwnProperty('jobDescriptionId'))
        obj.jobDescriptionId = ApiClient.convertToType(data['jobDescriptionId'], 'Number');
      if (data.hasOwnProperty('candidateIds'))
        obj.candidateIds = ApiClient.convertToType(data['candidateIds'], ['Number']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
TaggedCandidates.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
TaggedCandidates.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
TaggedCandidates.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
TaggedCandidates.prototype.uniqueGuid = undefined;

/**
 * @member {Number} userId
 */
TaggedCandidates.prototype.userId = undefined;

/**
 * @member {Number} jobDescriptionId
 */
TaggedCandidates.prototype.jobDescriptionId = undefined;

/**
 * @member {Array.<Number>} candidateIds
 */
TaggedCandidates.prototype.candidateIds = undefined;

