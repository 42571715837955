/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import CandidateAboutDto from '../model/CandidateAboutDto';
import CandidateAboutDtoApiResponse from '../model/CandidateAboutDtoApiResponse';
import CandidateActivityAndHobbieDto from '../model/CandidateActivityAndHobbieDto';
import CandidateActivityAndHobbieDtoApiResponse from '../model/CandidateActivityAndHobbieDtoApiResponse';
import CandidateCertificateInfoDtoApiResponse from '../model/CandidateCertificateInfoDtoApiResponse';
import CandidateCompactDtoIEnumerableApiResponse from '../model/CandidateCompactDtoIEnumerableApiResponse';
import CandidateCompactDtoPagedListApiResponse from '../model/CandidateCompactDtoPagedListApiResponse';
import CandidateContactDtoApiResponse from '../model/CandidateContactDtoApiResponse';
import CandidateDetailsDtoApiResponse from '../model/CandidateDetailsDtoApiResponse';
import CandidateDetailsEditDto from '../model/CandidateDetailsEditDto';
import CandidateDtoApiResponse from '../model/CandidateDtoApiResponse';
import CandidateDtoIEnumerableApiResponse from '../model/CandidateDtoIEnumerableApiResponse';
import CandidateDtoPagedListApiResponse from '../model/CandidateDtoPagedListApiResponse';
import CandidateEmployerDtoApiResponse from '../model/CandidateEmployerDtoApiResponse';
import CandidateHeadLineDto from '../model/CandidateHeadLineDto';
import CandidateHeadLineDtoApiResponse from '../model/CandidateHeadLineDtoApiResponse';
import CandidateOtherPersonalInfoDtoApiResponse from '../model/CandidateOtherPersonalInfoDtoApiResponse';
import CandidateOtherPersonalInfoEditDto from '../model/CandidateOtherPersonalInfoEditDto';
import CandidateProfileDtoApiResponse from '../model/CandidateProfileDtoApiResponse';
import CandidateProjectsDtoApiResponse from '../model/CandidateProjectsDtoApiResponse';
import CandidateSkillsDtoApiResponse from '../model/CandidateSkillsDtoApiResponse';
import CandidateSkillsEditDto from '../model/CandidateSkillsEditDto';
import CandidateViewDtoIEnumerableApiResponse from '../model/CandidateViewDtoIEnumerableApiResponse';
import ChatGPTMapDtoApiResponse from '../model/ChatGPTMapDtoApiResponse';

/**
* Candidate service.
* @module api/CandidateApi
* @version v3.17(PROD)
*/
export default class CandidateApi {

    /**
    * Constructs a new CandidateApi. 
    * @alias module:api/CandidateApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiCandidateGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CandidateApi~apiCandidateGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetAllAttachedToJdGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetAllAttachedToJdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isAttachedToJd 
     * @param {module:api/CandidateApi~apiCandidateGetAllAttachedToJdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetAllAttachedToJdGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'isAttachedToJd': opts['isAttachedToJd']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetAllAttachedToJd', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetAllBySearchFilterGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetAllBySearchFilterGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.skill 
     * @param {String} opts.skillPlatform 
     * @param {Number} opts.minimumExperience 
     * @param {Number} opts.maximumExperience 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/CandidateApi~apiCandidateGetAllBySearchFilterGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetAllBySearchFilterGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'Skill': opts['skill'],'SkillPlatform': opts['skillPlatform'],'MinimumExperience': opts['minimumExperience'],'MaximumExperience': opts['maximumExperience'],'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetAllBySearchFilter', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetAllCandidateDataGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetAllCandidateDataGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateViewDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CandidateApi~apiCandidateGetAllCandidateDataGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetAllCandidateDataGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateViewDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetAllCandidateData', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetAllCandidatePageGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetAllCandidatePageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/CandidateApi~apiCandidateGetAllCandidatePageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetAllCandidatePageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetAllCandidatePage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetByGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetBySlugSlugGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetBySlugSlugGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} slug 
     * @param {module:api/CandidateApi~apiCandidateGetBySlugSlugGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetBySlugSlugGet(slug, callback) {
      
      let postBody = null;
      // verify the required parameter 'slug' is set
      if (slug === undefined || slug === null) {
        throw new Error("Missing the required parameter 'slug' when calling apiCandidateGetBySlugSlugGet");
      }

      let pathParams = {
        'slug': slug
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetBySlug/{slug}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetByTestSkillIdGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetByTestSkillIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ChatGPTMapDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateApi~apiCandidateGetByTestSkillIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetByTestSkillIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateGetByTestSkillIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ChatGPTMapDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetByTestSkill/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetCandidateCertificateByGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetCandidateCertificateByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateCertificateInfoDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetCandidateCertificateByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetCandidateCertificateByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetCandidateCertificateByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateCertificateInfoDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetCandidateCertificateByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetCandidateProfileGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetCandidateProfileGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateProfileDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetCandidateProfileGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetCandidateProfileGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetCandidateProfileGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateProfileDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetCandidateProfile/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetContactDetailsGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetContactDetailsGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateContactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetContactDetailsGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetContactDetailsGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetContactDetailsGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateContactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetContactDetailsGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetEmployeeDataGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetEmployeeDataGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateEmployerDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetEmployeeDataGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetEmployeeDataGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetEmployeeDataGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateEmployerDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetEmployeeDataGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetOtherDetailsByGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetOtherDetailsByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDetailsDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetOtherDetailsByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetOtherDetailsByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetOtherDetailsByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDetailsDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetOtherDetailsByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetOtherPersonalDataGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetOtherPersonalDataGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateOtherPersonalInfoDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetOtherPersonalDataGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetOtherPersonalDataGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetOtherPersonalDataGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateOtherPersonalInfoDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetOtherPersonalDataGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetProjectsDataGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetProjectsDataGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateProjectsDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetProjectsDataGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetProjectsDataGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetProjectsDataGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateProjectsDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetProjectsDataGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateGetSkillByGuidGuidGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateGetSkillByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillsDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CandidateApi~apiCandidateGetSkillByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateGetSkillByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCandidateGetSkillByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillsDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/GetSkillByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateIdGet operation.
     * @callback moduleapi/CandidateApi~apiCandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateApi~apiCandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateAboutInfoPost operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateAboutInfoPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateAboutDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateAboutDto} opts.body 
     * @param {module:api/CandidateApi~apiCandidateUpdateAboutInfoPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateAboutInfoPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateAboutDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateAboutInfo', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateActivityAndHobbiesPost operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateActivityAndHobbiesPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateActivityAndHobbieDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateActivityAndHobbieDto} opts.body 
     * @param {module:api/CandidateApi~apiCandidateUpdateActivityAndHobbiesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateActivityAndHobbiesPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateActivityAndHobbieDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateActivityAndHobbies', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateBasicDocumentDetailsCandidateIdPost operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateBasicDocumentDetailsCandidateIdPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {Object} opts Optional parameters
     * @param {Blob} opts.resumeFile 
     * @param {Blob} opts.collegeIdImage 
     * @param {Boolean} opts.isStudent 
     * @param {Number} opts.primaryEducationInstituteId 
     * @param {String} opts.primaryEducationInstituteName 
     * @param {Number} opts.modifiedBy 
     * @param {module:api/CandidateApi~apiCandidateUpdateBasicDocumentDetailsCandidateIdPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateBasicDocumentDetailsCandidateIdPost(candidateId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateUpdateBasicDocumentDetailsCandidateIdPost");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        'isStudent': opts['isStudent'],'primaryEducationInstituteId': opts['primaryEducationInstituteId'],'primaryEducationInstituteName': opts['primaryEducationInstituteName'],'modifiedBy': opts['modifiedBy']
      };
      let headerParams = {
        
      };
      let formParams = {
        'resumeFile': opts['resumeFile'],'collegeIdImage': opts['collegeIdImage']
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateBasicDocumentDetails/{candidateId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateCandidateskillPut operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateCandidateskillPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillsDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateSkillsEditDto} opts.body 
     * @param {module:api/CandidateApi~apiCandidateUpdateCandidateskillPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateCandidateskillPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillsDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateCandidateskill', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateChatGPTExtractionCandidateIdPost operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateChatGPTExtractionCandidateIdPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.requestId 
     * @param {String} opts.action 
     * @param {Number} opts.modifiedby 
     * @param {module:api/CandidateApi~apiCandidateUpdateChatGPTExtractionCandidateIdPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateChatGPTExtractionCandidateIdPost(candidateId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateUpdateChatGPTExtractionCandidateIdPost");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        'requestId': opts['requestId'],'action': opts['action'],'modifiedby': opts['modifiedby']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateChatGPTExtraction/{candidateId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateOtherDetailsPut operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateOtherDetailsPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateDetailsDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateDetailsEditDto} opts.body 
     * @param {module:api/CandidateApi~apiCandidateUpdateOtherDetailsPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateOtherDetailsPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateDetailsDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateOtherDetails', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateOtherPersonalDataPut operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateOtherPersonalDataPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateOtherPersonalInfoDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateOtherPersonalInfoEditDto} opts.body 
     * @param {module:api/CandidateApi~apiCandidateUpdateOtherPersonalDataPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateOtherPersonalDataPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateOtherPersonalInfoDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateOtherPersonalData', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateUpdateProfileHeadLinePost operation.
     * @callback moduleapi/CandidateApi~apiCandidateUpdateProfileHeadLinePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateHeadLineDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateHeadLineDto} opts.body 
     * @param {module:api/CandidateApi~apiCandidateUpdateProfileHeadLinePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateUpdateProfileHeadLinePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateHeadLineDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Candidate/UpdateProfileHeadLine', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}