/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MStepAttributeCreateDto from './MStepAttributeCreateDto';
import MStepOutcomeCommunicationCreateDto from './MStepOutcomeCommunicationCreateDto';
import MStepOutcomeCreateDto from './MStepOutcomeCreateDto';
import MStepTextCreateDto from './MStepTextCreateDto';
import StatusType from './StatusType';

/**
 * The MStepCreateDto model module.
 * @module model/MStepCreateDto
 * @version v3.17(PROD)
 */
export default class MStepCreateDto {
  /**
   * Constructs a new <code>MStepCreateDto</code>.
   * @alias module:model/MStepCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MStepCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MStepCreateDto} obj Optional instance to populate.
   * @return {module:model/MStepCreateDto} The populated <code>MStepCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MStepCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('isMandate'))
        obj.isMandate = ApiClient.convertToType(data['isMandate'], 'Boolean');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = StatusType.constructFromObject(data['status']);
      if (data.hasOwnProperty('isInternal'))
        obj.isInternal = ApiClient.convertToType(data['isInternal'], 'Boolean');
      if (data.hasOwnProperty('mStepText'))
        obj.mStepText = ApiClient.convertToType(data['mStepText'], [MStepTextCreateDto]);
      if (data.hasOwnProperty('mStepAttribute'))
        obj.mStepAttribute = ApiClient.convertToType(data['mStepAttribute'], [MStepAttributeCreateDto]);
      if (data.hasOwnProperty('mStepOutcome'))
        obj.mStepOutcome = ApiClient.convertToType(data['mStepOutcome'], [MStepOutcomeCreateDto]);
      if (data.hasOwnProperty('mStepOutcomeCommunication'))
        obj.mStepOutcomeCommunication = ApiClient.convertToType(data['mStepOutcomeCommunication'], [MStepOutcomeCommunicationCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
MStepCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
MStepCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
MStepCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
MStepCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
MStepCreateDto.prototype.name = undefined;

/**
 * @member {Boolean} isMandate
 */
MStepCreateDto.prototype.isMandate = undefined;

/**
 * @member {String} description
 */
MStepCreateDto.prototype.description = undefined;

/**
 * @member {module:model/StatusType} status
 */
MStepCreateDto.prototype.status = undefined;

/**
 * @member {Boolean} isInternal
 */
MStepCreateDto.prototype.isInternal = undefined;

/**
 * @member {Array.<module:model/MStepTextCreateDto>} mStepText
 */
MStepCreateDto.prototype.mStepText = undefined;

/**
 * @member {Array.<module:model/MStepAttributeCreateDto>} mStepAttribute
 */
MStepCreateDto.prototype.mStepAttribute = undefined;

/**
 * @member {Array.<module:model/MStepOutcomeCreateDto>} mStepOutcome
 */
MStepCreateDto.prototype.mStepOutcome = undefined;

/**
 * @member {Array.<module:model/MStepOutcomeCommunicationCreateDto>} mStepOutcomeCommunication
 */
MStepCreateDto.prototype.mStepOutcomeCommunication = undefined;

