/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import EmploymentStatusType from './EmploymentStatusType';
import GenderType from './GenderType';

/**
 * The RSCandidatePersonalInfoDto model module.
 * @module model/RSCandidatePersonalInfoDto
 * @version v3.17(PROD)
 */
export default class RSCandidatePersonalInfoDto {
  /**
   * Constructs a new <code>RSCandidatePersonalInfoDto</code>.
   * @alias module:model/RSCandidatePersonalInfoDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RSCandidatePersonalInfoDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RSCandidatePersonalInfoDto} obj Optional instance to populate.
   * @return {module:model/RSCandidatePersonalInfoDto} The populated <code>RSCandidatePersonalInfoDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RSCandidatePersonalInfoDto();
      if (data.hasOwnProperty('employmentStatus'))
        obj.employmentStatus = EmploymentStatusType.constructFromObject(data['employmentStatus']);
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('contactNumber'))
        obj.contactNumber = ApiClient.convertToType(data['contactNumber'], 'String');
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], 'String');
      if (data.hasOwnProperty('middleName'))
        obj.middleName = ApiClient.convertToType(data['middleName'], 'String');
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], 'String');
      if (data.hasOwnProperty('fullName'))
        obj.fullName = ApiClient.convertToType(data['fullName'], 'String');
      if (data.hasOwnProperty('isStudent'))
        obj.isStudent = ApiClient.convertToType(data['isStudent'], 'Boolean');
      if (data.hasOwnProperty('dateOfBirth'))
        obj.dateOfBirth = ApiClient.convertToType(data['dateOfBirth'], 'Date');
      if (data.hasOwnProperty('gender'))
        obj.gender = GenderType.constructFromObject(data['gender']);
      if (data.hasOwnProperty('institutionName'))
        obj.institutionName = ApiClient.convertToType(data['institutionName'], 'String');
      if (data.hasOwnProperty('isFresher'))
        obj.isFresher = ApiClient.convertToType(data['isFresher'], 'Boolean');
      if (data.hasOwnProperty('totalExperienceInYears'))
        obj.totalExperienceInYears = ApiClient.convertToType(data['totalExperienceInYears'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {module:model/EmploymentStatusType} employmentStatus
 */
RSCandidatePersonalInfoDto.prototype.employmentStatus = undefined;

/**
 * @member {String} email
 */
RSCandidatePersonalInfoDto.prototype.email = undefined;

/**
 * @member {String} contactNumber
 */
RSCandidatePersonalInfoDto.prototype.contactNumber = undefined;

/**
 * @member {String} firstName
 */
RSCandidatePersonalInfoDto.prototype.firstName = undefined;

/**
 * @member {String} middleName
 */
RSCandidatePersonalInfoDto.prototype.middleName = undefined;

/**
 * @member {String} lastName
 */
RSCandidatePersonalInfoDto.prototype.lastName = undefined;

/**
 * @member {String} fullName
 */
RSCandidatePersonalInfoDto.prototype.fullName = undefined;

/**
 * @member {Boolean} isStudent
 */
RSCandidatePersonalInfoDto.prototype.isStudent = undefined;

/**
 * @member {Date} dateOfBirth
 */
RSCandidatePersonalInfoDto.prototype.dateOfBirth = undefined;

/**
 * @member {module:model/GenderType} gender
 */
RSCandidatePersonalInfoDto.prototype.gender = undefined;

/**
 * @member {String} institutionName
 */
RSCandidatePersonalInfoDto.prototype.institutionName = undefined;

/**
 * @member {Boolean} isFresher
 */
RSCandidatePersonalInfoDto.prototype.isFresher = undefined;

/**
 * @member {Number} totalExperienceInYears
 */
RSCandidatePersonalInfoDto.prototype.totalExperienceInYears = undefined;

