/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AddressDto from './AddressDto';
import CompanyDto from './CompanyDto';
import JobTitleDto from './JobTitleDto';
import ProjectDto from './ProjectDto';

/**
 * The EmployerDto model module.
 * @module model/EmployerDto
 * @version v3.17(PROD)
 */
export default class EmployerDto {
  /**
   * Constructs a new <code>EmployerDto</code>.
   * @alias module:model/EmployerDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmployerDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmployerDto} obj Optional instance to populate.
   * @return {module:model/EmployerDto} The populated <code>EmployerDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmployerDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('companyName'))
        obj.companyName = ApiClient.convertToType(data['companyName'], 'String');
      if (data.hasOwnProperty('companyId'))
        obj.companyId = ApiClient.convertToType(data['companyId'], 'Number');
      if (data.hasOwnProperty('company'))
        obj.company = CompanyDto.constructFromObject(data['company']);
      if (data.hasOwnProperty('addressId'))
        obj.addressId = ApiClient.convertToType(data['addressId'], 'Number');
      if (data.hasOwnProperty('address'))
        obj.address = AddressDto.constructFromObject(data['address']);
      if (data.hasOwnProperty('jobTitleId'))
        obj.jobTitleId = ApiClient.convertToType(data['jobTitleId'], 'Number');
      if (data.hasOwnProperty('jobTitle'))
        obj.jobTitle = JobTitleDto.constructFromObject(data['jobTitle']);
      if (data.hasOwnProperty('jobPeriod'))
        obj.jobPeriod = ApiClient.convertToType(data['jobPeriod'], 'String');
      if (data.hasOwnProperty('startDate'))
        obj.startDate = ApiClient.convertToType(data['startDate'], 'Date');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'Date');
      if (data.hasOwnProperty('isCurrentEmployer'))
        obj.isCurrentEmployer = ApiClient.convertToType(data['isCurrentEmployer'], 'Boolean');
      if (data.hasOwnProperty('jobDescription'))
        obj.jobDescription = ApiClient.convertToType(data['jobDescription'], 'String');
      if (data.hasOwnProperty('projects'))
        obj.projects = ApiClient.convertToType(data['projects'], [ProjectDto]);
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
EmployerDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
EmployerDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
EmployerDto.prototype.modifiedBy = undefined;

/**
 * @member {String} companyName
 */
EmployerDto.prototype.companyName = undefined;

/**
 * @member {Number} companyId
 */
EmployerDto.prototype.companyId = undefined;

/**
 * @member {module:model/CompanyDto} company
 */
EmployerDto.prototype.company = undefined;

/**
 * @member {Number} addressId
 */
EmployerDto.prototype.addressId = undefined;

/**
 * @member {module:model/AddressDto} address
 */
EmployerDto.prototype.address = undefined;

/**
 * @member {Number} jobTitleId
 */
EmployerDto.prototype.jobTitleId = undefined;

/**
 * @member {module:model/JobTitleDto} jobTitle
 */
EmployerDto.prototype.jobTitle = undefined;

/**
 * @member {String} jobPeriod
 */
EmployerDto.prototype.jobPeriod = undefined;

/**
 * @member {Date} startDate
 */
EmployerDto.prototype.startDate = undefined;

/**
 * @member {Date} endDate
 */
EmployerDto.prototype.endDate = undefined;

/**
 * @member {Boolean} isCurrentEmployer
 */
EmployerDto.prototype.isCurrentEmployer = undefined;

/**
 * @member {String} jobDescription
 */
EmployerDto.prototype.jobDescription = undefined;

/**
 * @member {Array.<module:model/ProjectDto>} projects
 */
EmployerDto.prototype.projects = undefined;

/**
 * @member {Number} candidateId
 */
EmployerDto.prototype.candidateId = undefined;

