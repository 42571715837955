/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PathwayOutcomeAttributeCreateDto from './PathwayOutcomeAttributeCreateDto';

/**
 * The PathwayOutcomeCreateDto model module.
 * @module model/PathwayOutcomeCreateDto
 * @version v3.17(PROD)
 */
export default class PathwayOutcomeCreateDto {
  /**
   * Constructs a new <code>PathwayOutcomeCreateDto</code>.
   * @alias module:model/PathwayOutcomeCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayOutcomeCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayOutcomeCreateDto} obj Optional instance to populate.
   * @return {module:model/PathwayOutcomeCreateDto} The populated <code>PathwayOutcomeCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayOutcomeCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('ePathwayId'))
        obj.ePathwayId = ApiClient.convertToType(data['ePathwayId'], 'Number');
      if (data.hasOwnProperty('mPathwayOutcomeId'))
        obj.mPathwayOutcomeId = ApiClient.convertToType(data['mPathwayOutcomeId'], 'Number');
      if (data.hasOwnProperty('ePathwayOutcomeAttribute'))
        obj.ePathwayOutcomeAttribute = ApiClient.convertToType(data['ePathwayOutcomeAttribute'], [PathwayOutcomeAttributeCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayOutcomeCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
PathwayOutcomeCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
PathwayOutcomeCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayOutcomeCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {Number} ePathwayId
 */
PathwayOutcomeCreateDto.prototype.ePathwayId = undefined;

/**
 * @member {Number} mPathwayOutcomeId
 */
PathwayOutcomeCreateDto.prototype.mPathwayOutcomeId = undefined;

/**
 * @member {Array.<module:model/PathwayOutcomeAttributeCreateDto>} ePathwayOutcomeAttribute
 */
PathwayOutcomeCreateDto.prototype.ePathwayOutcomeAttribute = undefined;

