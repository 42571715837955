/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PathwayStepAttributeCreateDto from './PathwayStepAttributeCreateDto';
import PathwayStepOutcomeCreateDto from './PathwayStepOutcomeCreateDto';
import PathwayStepTextCreateDto from './PathwayStepTextCreateDto';

/**
 * The PathwayStepCreateDto model module.
 * @module model/PathwayStepCreateDto
 * @version v3.17(PROD)
 */
export default class PathwayStepCreateDto {
  /**
   * Constructs a new <code>PathwayStepCreateDto</code>.
   * @alias module:model/PathwayStepCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayStepCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayStepCreateDto} obj Optional instance to populate.
   * @return {module:model/PathwayStepCreateDto} The populated <code>PathwayStepCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayStepCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('sequenceNumber'))
        obj.sequenceNumber = ApiClient.convertToType(data['sequenceNumber'], 'Number');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('isActive'))
        obj.isActive = ApiClient.convertToType(data['isActive'], 'Boolean');
      if (data.hasOwnProperty('isManualTrigger'))
        obj.isManualTrigger = ApiClient.convertToType(data['isManualTrigger'], 'Boolean');
      if (data.hasOwnProperty('ePathwayId'))
        obj.ePathwayId = ApiClient.convertToType(data['ePathwayId'], 'Number');
      if (data.hasOwnProperty('mStepId'))
        obj.mStepId = ApiClient.convertToType(data['mStepId'], 'Number');
      if (data.hasOwnProperty('ePathwayStepText'))
        obj.ePathwayStepText = ApiClient.convertToType(data['ePathwayStepText'], [PathwayStepTextCreateDto]);
      if (data.hasOwnProperty('ePathwayStepOutcome'))
        obj.ePathwayStepOutcome = ApiClient.convertToType(data['ePathwayStepOutcome'], [PathwayStepOutcomeCreateDto]);
      if (data.hasOwnProperty('ePathwayStepAttribute'))
        obj.ePathwayStepAttribute = ApiClient.convertToType(data['ePathwayStepAttribute'], [PathwayStepAttributeCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayStepCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
PathwayStepCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
PathwayStepCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayStepCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} code
 */
PathwayStepCreateDto.prototype.code = undefined;

/**
 * @member {Number} sequenceNumber
 */
PathwayStepCreateDto.prototype.sequenceNumber = undefined;

/**
 * @member {String} description
 */
PathwayStepCreateDto.prototype.description = undefined;

/**
 * @member {Boolean} isActive
 */
PathwayStepCreateDto.prototype.isActive = undefined;

/**
 * @member {Boolean} isManualTrigger
 */
PathwayStepCreateDto.prototype.isManualTrigger = undefined;

/**
 * @member {Number} ePathwayId
 */
PathwayStepCreateDto.prototype.ePathwayId = undefined;

/**
 * @member {Number} mStepId
 */
PathwayStepCreateDto.prototype.mStepId = undefined;

/**
 * @member {Array.<module:model/PathwayStepTextCreateDto>} ePathwayStepText
 */
PathwayStepCreateDto.prototype.ePathwayStepText = undefined;

/**
 * @member {Array.<module:model/PathwayStepOutcomeCreateDto>} ePathwayStepOutcome
 */
PathwayStepCreateDto.prototype.ePathwayStepOutcome = undefined;

/**
 * @member {Array.<module:model/PathwayStepAttributeCreateDto>} ePathwayStepAttribute
 */
PathwayStepCreateDto.prototype.ePathwayStepAttribute = undefined;

