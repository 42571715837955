/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ContactType from './ContactType';
import GenderType from './GenderType';

/**
 * The ContactDto model module.
 * @module model/ContactDto
 * @version v3.17(PROD)
 */
export default class ContactDto {
  /**
   * Constructs a new <code>ContactDto</code>.
   * @alias module:model/ContactDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ContactDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ContactDto} obj Optional instance to populate.
   * @return {module:model/ContactDto} The populated <code>ContactDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ContactDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('contactType'))
        obj.contactType = ContactType.constructFromObject(data['contactType']);
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], 'String');
      if (data.hasOwnProperty('middleName'))
        obj.middleName = ApiClient.convertToType(data['middleName'], 'String');
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], 'String');
      if (data.hasOwnProperty('contactNumber'))
        obj.contactNumber = ApiClient.convertToType(data['contactNumber'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('designation'))
        obj.designation = ApiClient.convertToType(data['designation'], 'String');
      if (data.hasOwnProperty('department'))
        obj.department = ApiClient.convertToType(data['department'], 'String');
      if (data.hasOwnProperty('dateOfBirth'))
        obj.dateOfBirth = ApiClient.convertToType(data['dateOfBirth'], 'Date');
      if (data.hasOwnProperty('gender'))
        obj.gender = GenderType.constructFromObject(data['gender']);
      if (data.hasOwnProperty('bloodGroup'))
        obj.bloodGroup = ApiClient.convertToType(data['bloodGroup'], 'String');
      if (data.hasOwnProperty('sourceId'))
        obj.sourceId = ApiClient.convertToType(data['sourceId'], 'Number');
      if (data.hasOwnProperty('clientId'))
        obj.clientId = ApiClient.convertToType(data['clientId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ContactDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
ContactDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
ContactDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
ContactDto.prototype.uniqueGuid = undefined;

/**
 * @member {module:model/ContactType} contactType
 */
ContactDto.prototype.contactType = undefined;

/**
 * @member {String} firstName
 */
ContactDto.prototype.firstName = undefined;

/**
 * @member {String} middleName
 */
ContactDto.prototype.middleName = undefined;

/**
 * @member {String} lastName
 */
ContactDto.prototype.lastName = undefined;

/**
 * @member {String} contactNumber
 */
ContactDto.prototype.contactNumber = undefined;

/**
 * @member {String} email
 */
ContactDto.prototype.email = undefined;

/**
 * @member {String} designation
 */
ContactDto.prototype.designation = undefined;

/**
 * @member {String} department
 */
ContactDto.prototype.department = undefined;

/**
 * @member {Date} dateOfBirth
 */
ContactDto.prototype.dateOfBirth = undefined;

/**
 * @member {module:model/GenderType} gender
 */
ContactDto.prototype.gender = undefined;

/**
 * @member {String} bloodGroup
 */
ContactDto.prototype.bloodGroup = undefined;

/**
 * @member {Number} sourceId
 */
ContactDto.prototype.sourceId = undefined;

/**
 * @member {Number} clientId
 */
ContactDto.prototype.clientId = undefined;

