/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import CustomCandidateStatusDtoApiResponse from '../model/CustomCandidateStatusDtoApiResponse';
import JdAndCandidateStatusDtoApiResponse from '../model/JdAndCandidateStatusDtoApiResponse';
import JdAndCandidateStatusDtoIEnumerableApiResponse from '../model/JdAndCandidateStatusDtoIEnumerableApiResponse';

/**
* JdAndCandidateStatus service.
* @module api/JdAndCandidateStatusApi
* @version v3.17(PROD)
*/
export default class JdAndCandidateStatusApi {

    /**
    * Constructs a new JdAndCandidateStatusApi. 
    * @alias module:api/JdAndCandidateStatusApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiJdAndCandidateStatusGetAllCandidateStatusByJdIdJobDescriptionIdGet operation.
     * @callback moduleapi/JdAndCandidateStatusApi~apiJdAndCandidateStatusGetAllCandidateStatusByJdIdJobDescriptionIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JdAndCandidateStatusDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} jobDescriptionId 
     * @param {module:api/JdAndCandidateStatusApi~apiJdAndCandidateStatusGetAllCandidateStatusByJdIdJobDescriptionIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdAndCandidateStatusGetAllCandidateStatusByJdIdJobDescriptionIdGet(jobDescriptionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'jobDescriptionId' is set
      if (jobDescriptionId === undefined || jobDescriptionId === null) {
        throw new Error("Missing the required parameter 'jobDescriptionId' when calling apiJdAndCandidateStatusGetAllCandidateStatusByJdIdJobDescriptionIdGet");
      }

      let pathParams = {
        'jobDescriptionId': jobDescriptionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JdAndCandidateStatusDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JdAndCandidateStatus/GetAllCandidateStatusByJdId/{jobDescriptionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJdAndCandidateStatusGetCandidateStatusByIdJobDescriptionIdCandidateIdGet operation.
     * @callback moduleapi/JdAndCandidateStatusApi~apiJdAndCandidateStatusGetCandidateStatusByIdJobDescriptionIdCandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomCandidateStatusDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} jobDescriptionId 
     * @param {Number} candidateId 
     * @param {module:api/JdAndCandidateStatusApi~apiJdAndCandidateStatusGetCandidateStatusByIdJobDescriptionIdCandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdAndCandidateStatusGetCandidateStatusByIdJobDescriptionIdCandidateIdGet(jobDescriptionId, candidateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'jobDescriptionId' is set
      if (jobDescriptionId === undefined || jobDescriptionId === null) {
        throw new Error("Missing the required parameter 'jobDescriptionId' when calling apiJdAndCandidateStatusGetCandidateStatusByIdJobDescriptionIdCandidateIdGet");
      }
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiJdAndCandidateStatusGetCandidateStatusByIdJobDescriptionIdCandidateIdGet");
      }

      let pathParams = {
        'jobDescriptionId': jobDescriptionId,'candidateId': candidateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CustomCandidateStatusDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JdAndCandidateStatus/GetCandidateStatusById/{jobDescriptionId}/{candidateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJdAndCandidateStatusGetSequenceIdGet operation.
     * @callback moduleapi/JdAndCandidateStatusApi~apiJdAndCandidateStatusGetSequenceIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JdAndCandidateStatusDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} sequenceId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.jdId 
     * @param {module:api/JdAndCandidateStatusApi~apiJdAndCandidateStatusGetSequenceIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdAndCandidateStatusGetSequenceIdGet(sequenceId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'sequenceId' is set
      if (sequenceId === undefined || sequenceId === null) {
        throw new Error("Missing the required parameter 'sequenceId' when calling apiJdAndCandidateStatusGetSequenceIdGet");
      }

      let pathParams = {
        'SequenceId': sequenceId
      };
      let queryParams = {
        'jdId': opts['jdId']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JdAndCandidateStatusDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JdAndCandidateStatus/Get/{SequenceId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJdAndCandidateStatusUpdateCandidateSourcingSequencePut operation.
     * @callback moduleapi/JdAndCandidateStatusApi~apiJdAndCandidateStatusUpdateCandidateSourcingSequencePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JdAndCandidateStatusDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.type 
     * @param {Number} opts.id 
     * @param {String} opts.remarks 
     * @param {Number} opts.modifiedBy 
     * @param {module:api/JdAndCandidateStatusApi~apiJdAndCandidateStatusUpdateCandidateSourcingSequencePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdAndCandidateStatusUpdateCandidateSourcingSequencePut(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'type': opts['type'],'id': opts['id'],'remarks': opts['remarks'],'modifiedBy': opts['modifiedBy']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JdAndCandidateStatusDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JdAndCandidateStatus/UpdateCandidateSourcingSequence', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJdAndCandidateStatusUpdateSkippedCandidatePut operation.
     * @callback moduleapi/JdAndCandidateStatusApi~apiJdAndCandidateStatusUpdateSkippedCandidatePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JdAndCandidateStatusDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @param {String} opts.remarks 
     * @param {Number} opts.modifiedBy 
     * @param {module:api/JdAndCandidateStatusApi~apiJdAndCandidateStatusUpdateSkippedCandidatePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdAndCandidateStatusUpdateSkippedCandidatePut(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'id': opts['id'],'remarks': opts['remarks'],'modifiedBy': opts['modifiedBy']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JdAndCandidateStatusDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JdAndCandidateStatus/UpdateSkippedCandidate', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}