/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AssessmentDto from './AssessmentDto';
import JobDescriptionDto from './JobDescriptionDto';

/**
 * The JdAndAssessmentDto model module.
 * @module model/JdAndAssessmentDto
 * @version v3.17(PROD)
 */
export default class JdAndAssessmentDto {
  /**
   * Constructs a new <code>JdAndAssessmentDto</code>.
   * @alias module:model/JdAndAssessmentDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>JdAndAssessmentDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JdAndAssessmentDto} obj Optional instance to populate.
   * @return {module:model/JdAndAssessmentDto} The populated <code>JdAndAssessmentDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JdAndAssessmentDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('jobDescriptionId'))
        obj.jobDescriptionId = ApiClient.convertToType(data['jobDescriptionId'], 'Number');
      if (data.hasOwnProperty('jobDescription'))
        obj.jobDescription = JobDescriptionDto.constructFromObject(data['jobDescription']);
      if (data.hasOwnProperty('assessmentId'))
        obj.assessmentId = ApiClient.convertToType(data['assessmentId'], 'Number');
      if (data.hasOwnProperty('assessment'))
        obj.assessment = AssessmentDto.constructFromObject(data['assessment']);
      if (data.hasOwnProperty('note'))
        obj.note = ApiClient.convertToType(data['note'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JdAndAssessmentDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
JdAndAssessmentDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
JdAndAssessmentDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
JdAndAssessmentDto.prototype.uniqueGuid = undefined;

/**
 * @member {Number} jobDescriptionId
 */
JdAndAssessmentDto.prototype.jobDescriptionId = undefined;

/**
 * @member {module:model/JobDescriptionDto} jobDescription
 */
JdAndAssessmentDto.prototype.jobDescription = undefined;

/**
 * @member {Number} assessmentId
 */
JdAndAssessmentDto.prototype.assessmentId = undefined;

/**
 * @member {module:model/AssessmentDto} assessment
 */
JdAndAssessmentDto.prototype.assessment = undefined;

/**
 * @member {String} note
 */
JdAndAssessmentDto.prototype.note = undefined;

