/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MStepDto from './MStepDto';
import PathwayStepAttributeDto from './PathwayStepAttributeDto';
import PathwayStepOutcomeDto from './PathwayStepOutcomeDto';
import PathwayStepTextDto from './PathwayStepTextDto';

/**
 * The PathwayStepDto model module.
 * @module model/PathwayStepDto
 * @version v3.17(PROD)
 */
export default class PathwayStepDto {
  /**
   * Constructs a new <code>PathwayStepDto</code>.
   * @alias module:model/PathwayStepDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayStepDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayStepDto} obj Optional instance to populate.
   * @return {module:model/PathwayStepDto} The populated <code>PathwayStepDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayStepDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('sequenceNumber'))
        obj.sequenceNumber = ApiClient.convertToType(data['sequenceNumber'], 'Number');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('isActive'))
        obj.isActive = ApiClient.convertToType(data['isActive'], 'Boolean');
      if (data.hasOwnProperty('isManualTrigger'))
        obj.isManualTrigger = ApiClient.convertToType(data['isManualTrigger'], 'Boolean');
      if (data.hasOwnProperty('ePathwayId'))
        obj.ePathwayId = ApiClient.convertToType(data['ePathwayId'], 'Number');
      if (data.hasOwnProperty('mStepId'))
        obj.mStepId = ApiClient.convertToType(data['mStepId'], 'Number');
      if (data.hasOwnProperty('mStep'))
        obj.mStep = MStepDto.constructFromObject(data['mStep']);
      if (data.hasOwnProperty('ePathwayStepText'))
        obj.ePathwayStepText = ApiClient.convertToType(data['ePathwayStepText'], [PathwayStepTextDto]);
      if (data.hasOwnProperty('ePathwayStepOutcome'))
        obj.ePathwayStepOutcome = ApiClient.convertToType(data['ePathwayStepOutcome'], [PathwayStepOutcomeDto]);
      if (data.hasOwnProperty('ePathwayStepAttribute'))
        obj.ePathwayStepAttribute = ApiClient.convertToType(data['ePathwayStepAttribute'], [PathwayStepAttributeDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayStepDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
PathwayStepDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
PathwayStepDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayStepDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} code
 */
PathwayStepDto.prototype.code = undefined;

/**
 * @member {Number} sequenceNumber
 */
PathwayStepDto.prototype.sequenceNumber = undefined;

/**
 * @member {String} description
 */
PathwayStepDto.prototype.description = undefined;

/**
 * @member {Boolean} isActive
 */
PathwayStepDto.prototype.isActive = undefined;

/**
 * @member {Boolean} isManualTrigger
 */
PathwayStepDto.prototype.isManualTrigger = undefined;

/**
 * @member {Number} ePathwayId
 */
PathwayStepDto.prototype.ePathwayId = undefined;

/**
 * @member {Number} mStepId
 */
PathwayStepDto.prototype.mStepId = undefined;

/**
 * @member {module:model/MStepDto} mStep
 */
PathwayStepDto.prototype.mStep = undefined;

/**
 * @member {Array.<module:model/PathwayStepTextDto>} ePathwayStepText
 */
PathwayStepDto.prototype.ePathwayStepText = undefined;

/**
 * @member {Array.<module:model/PathwayStepOutcomeDto>} ePathwayStepOutcome
 */
PathwayStepDto.prototype.ePathwayStepOutcome = undefined;

/**
 * @member {Array.<module:model/PathwayStepAttributeDto>} ePathwayStepAttribute
 */
PathwayStepDto.prototype.ePathwayStepAttribute = undefined;

