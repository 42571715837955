/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MStepOutcomeDto from './MStepOutcomeDto';
import PathwayStepOutcomeCommunicationDto from './PathwayStepOutcomeCommunicationDto';

/**
 * The PathwayStepOutcomeDto model module.
 * @module model/PathwayStepOutcomeDto
 * @version v3.17(PROD)
 */
export default class PathwayStepOutcomeDto {
  /**
   * Constructs a new <code>PathwayStepOutcomeDto</code>.
   * @alias module:model/PathwayStepOutcomeDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayStepOutcomeDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayStepOutcomeDto} obj Optional instance to populate.
   * @return {module:model/PathwayStepOutcomeDto} The populated <code>PathwayStepOutcomeDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayStepOutcomeDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('ePathwayStepId'))
        obj.ePathwayStepId = ApiClient.convertToType(data['ePathwayStepId'], 'Number');
      if (data.hasOwnProperty('mStepOutcomeId'))
        obj.mStepOutcomeId = ApiClient.convertToType(data['mStepOutcomeId'], 'Number');
      if (data.hasOwnProperty('mStepOutcome'))
        obj.mStepOutcome = MStepOutcomeDto.constructFromObject(data['mStepOutcome']);
      if (data.hasOwnProperty('ePathwayStepOutcomeCommunication'))
        obj.ePathwayStepOutcomeCommunication = ApiClient.convertToType(data['ePathwayStepOutcomeCommunication'], [PathwayStepOutcomeCommunicationDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayStepOutcomeDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
PathwayStepOutcomeDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
PathwayStepOutcomeDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayStepOutcomeDto.prototype.uniqueGuid = undefined;

/**
 * @member {Number} ePathwayStepId
 */
PathwayStepOutcomeDto.prototype.ePathwayStepId = undefined;

/**
 * @member {Number} mStepOutcomeId
 */
PathwayStepOutcomeDto.prototype.mStepOutcomeId = undefined;

/**
 * @member {module:model/MStepOutcomeDto} mStepOutcome
 */
PathwayStepOutcomeDto.prototype.mStepOutcome = undefined;

/**
 * @member {Array.<module:model/PathwayStepOutcomeCommunicationDto>} ePathwayStepOutcomeCommunication
 */
PathwayStepOutcomeDto.prototype.ePathwayStepOutcomeCommunication = undefined;

