/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import BusinessVerticalCompactDtoApiResponse from '../model/BusinessVerticalCompactDtoApiResponse';
import BusinessVerticalCompactDtoIEnumerableApiResponse from '../model/BusinessVerticalCompactDtoIEnumerableApiResponse';
import BusinessVerticalCompactDtoPagedListApiResponse from '../model/BusinessVerticalCompactDtoPagedListApiResponse';
import BusinessVerticalCreateDto from '../model/BusinessVerticalCreateDto';
import BusinessVerticalDtoApiResponse from '../model/BusinessVerticalDtoApiResponse';
import BusinessVerticalEditDto from '../model/BusinessVerticalEditDto';

/**
* BusinessVertical service.
* @module api/BusinessVerticalApi
* @version v3.17(PROD)
*/
export default class BusinessVerticalApi {

    /**
    * Constructs a new BusinessVerticalApi. 
    * @alias module:api/BusinessVerticalApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiBusinessVerticalGet operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiBusinessVerticalGetAllByNameGet operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalGetAllByNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalGetAllByNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalGetAllByNameGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical/GetAllByName', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiBusinessVerticalGetAllByPageGet operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiBusinessVerticalGetByGuidGuidGet operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiBusinessVerticalGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalDtoApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiBusinessVerticalIdDelete operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiBusinessVerticalIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiBusinessVerticalIdGet operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiBusinessVerticalIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalDtoApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiBusinessVerticalPost operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/BusinessVerticalCreateDto} opts.body 
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalDtoApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiBusinessVerticalPut operation.
     * @callback moduleapi/BusinessVerticalApi~apiBusinessVerticalPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BusinessVerticalDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/BusinessVerticalEditDto} opts.body 
     * @param {module:api/BusinessVerticalApi~apiBusinessVerticalPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiBusinessVerticalPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BusinessVerticalDtoApiResponse;

      return this.apiClient.callApi(
        '/api/BusinessVertical', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}