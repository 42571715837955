/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AttributeType from './AttributeType';

/**
 * The MStepAttributeEditDto model module.
 * @module model/MStepAttributeEditDto
 * @version v3.17(PROD)
 */
export default class MStepAttributeEditDto {
  /**
   * Constructs a new <code>MStepAttributeEditDto</code>.
   * @alias module:model/MStepAttributeEditDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MStepAttributeEditDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MStepAttributeEditDto} obj Optional instance to populate.
   * @return {module:model/MStepAttributeEditDto} The populated <code>MStepAttributeEditDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MStepAttributeEditDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('attributeName'))
        obj.attributeName = ApiClient.convertToType(data['attributeName'], 'String');
      if (data.hasOwnProperty('attributeValue'))
        obj.attributeValue = ApiClient.convertToType(data['attributeValue'], 'String');
      if (data.hasOwnProperty('attributeType'))
        obj.attributeType = AttributeType.constructFromObject(data['attributeType']);
      if (data.hasOwnProperty('isCustomizable'))
        obj.isCustomizable = ApiClient.convertToType(data['isCustomizable'], 'Boolean');
      if (data.hasOwnProperty('isMandatory'))
        obj.isMandatory = ApiClient.convertToType(data['isMandatory'], 'Boolean');
      if (data.hasOwnProperty('apiUrl'))
        obj.apiUrl = ApiClient.convertToType(data['apiUrl'], 'String');
      if (data.hasOwnProperty('apiName'))
        obj.apiName = ApiClient.convertToType(data['apiName'], 'String');
      if (data.hasOwnProperty('mStepId'))
        obj.mStepId = ApiClient.convertToType(data['mStepId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
MStepAttributeEditDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
MStepAttributeEditDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
MStepAttributeEditDto.prototype.modifiedBy = undefined;

/**
 * @member {String} attributeName
 */
MStepAttributeEditDto.prototype.attributeName = undefined;

/**
 * @member {String} attributeValue
 */
MStepAttributeEditDto.prototype.attributeValue = undefined;

/**
 * @member {module:model/AttributeType} attributeType
 */
MStepAttributeEditDto.prototype.attributeType = undefined;

/**
 * @member {Boolean} isCustomizable
 */
MStepAttributeEditDto.prototype.isCustomizable = undefined;

/**
 * @member {Boolean} isMandatory
 */
MStepAttributeEditDto.prototype.isMandatory = undefined;

/**
 * @member {String} apiUrl
 */
MStepAttributeEditDto.prototype.apiUrl = undefined;

/**
 * @member {String} apiName
 */
MStepAttributeEditDto.prototype.apiName = undefined;

/**
 * @member {Number} mStepId
 */
MStepAttributeEditDto.prototype.mStepId = undefined;

