/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ClientAndAddressCreateDto from './ClientAndAddressCreateDto';
import ClientStatus from './ClientStatus';

/**
 * The ClientCreateDto model module.
 * @module model/ClientCreateDto
 * @version v3.17(PROD)
 */
export default class ClientCreateDto {
  /**
   * Constructs a new <code>ClientCreateDto</code>.
   * @alias module:model/ClientCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClientCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClientCreateDto} obj Optional instance to populate.
   * @return {module:model/ClientCreateDto} The populated <code>ClientCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClientCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('gstNumber'))
        obj.gstNumber = ApiClient.convertToType(data['gstNumber'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = ClientStatus.constructFromObject(data['status']);
      if (data.hasOwnProperty('isBranched'))
        obj.isBranched = ApiClient.convertToType(data['isBranched'], 'Boolean');
      if (data.hasOwnProperty('webSite'))
        obj.webSite = ApiClient.convertToType(data['webSite'], 'String');
      if (data.hasOwnProperty('companySize'))
        obj.companySize = ApiClient.convertToType(data['companySize'], 'String');
      if (data.hasOwnProperty('dateOfOnBoarding'))
        obj.dateOfOnBoarding = ApiClient.convertToType(data['dateOfOnBoarding'], 'Date');
      if (data.hasOwnProperty('typeOfService'))
        obj.typeOfService = ApiClient.convertToType(data['typeOfService'], 'String');
      if (data.hasOwnProperty('isActive'))
        obj.isActive = ApiClient.convertToType(data['isActive'], 'Boolean');
      if (data.hasOwnProperty('businessVerticalId'))
        obj.businessVerticalId = ApiClient.convertToType(data['businessVerticalId'], 'Number');
      if (data.hasOwnProperty('companyProfile'))
        obj.companyProfile = ApiClient.convertToType(data['companyProfile'], 'String');
      if (data.hasOwnProperty('clientAddresses'))
        obj.clientAddresses = ApiClient.convertToType(data['clientAddresses'], [ClientAndAddressCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ClientCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
ClientCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
ClientCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
ClientCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
ClientCreateDto.prototype.name = undefined;

/**
 * @member {String} gstNumber
 */
ClientCreateDto.prototype.gstNumber = undefined;

/**
 * @member {module:model/ClientStatus} status
 */
ClientCreateDto.prototype.status = undefined;

/**
 * @member {Boolean} isBranched
 */
ClientCreateDto.prototype.isBranched = undefined;

/**
 * @member {String} webSite
 */
ClientCreateDto.prototype.webSite = undefined;

/**
 * @member {String} companySize
 */
ClientCreateDto.prototype.companySize = undefined;

/**
 * @member {Date} dateOfOnBoarding
 */
ClientCreateDto.prototype.dateOfOnBoarding = undefined;

/**
 * @member {String} typeOfService
 */
ClientCreateDto.prototype.typeOfService = undefined;

/**
 * @member {Boolean} isActive
 */
ClientCreateDto.prototype.isActive = undefined;

/**
 * @member {Number} businessVerticalId
 */
ClientCreateDto.prototype.businessVerticalId = undefined;

/**
 * @member {String} companyProfile
 */
ClientCreateDto.prototype.companyProfile = undefined;

/**
 * @member {Array.<module:model/ClientAndAddressCreateDto>} clientAddresses
 */
ClientCreateDto.prototype.clientAddresses = undefined;

