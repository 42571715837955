/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MPathwayOutcomeDto from './MPathwayOutcomeDto';
import PathwayOutcomeAttributeDto from './PathwayOutcomeAttributeDto';

/**
 * The PathwayOutcomeDto model module.
 * @module model/PathwayOutcomeDto
 * @version v3.17(PROD)
 */
export default class PathwayOutcomeDto {
  /**
   * Constructs a new <code>PathwayOutcomeDto</code>.
   * @alias module:model/PathwayOutcomeDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayOutcomeDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayOutcomeDto} obj Optional instance to populate.
   * @return {module:model/PathwayOutcomeDto} The populated <code>PathwayOutcomeDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayOutcomeDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('ePathwayId'))
        obj.ePathwayId = ApiClient.convertToType(data['ePathwayId'], 'Number');
      if (data.hasOwnProperty('mPathwayOutcomeId'))
        obj.mPathwayOutcomeId = ApiClient.convertToType(data['mPathwayOutcomeId'], 'Number');
      if (data.hasOwnProperty('mPathwayOutcome'))
        obj.mPathwayOutcome = MPathwayOutcomeDto.constructFromObject(data['mPathwayOutcome']);
      if (data.hasOwnProperty('ePathwayOutcomeAttribute'))
        obj.ePathwayOutcomeAttribute = ApiClient.convertToType(data['ePathwayOutcomeAttribute'], [PathwayOutcomeAttributeDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayOutcomeDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
PathwayOutcomeDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
PathwayOutcomeDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayOutcomeDto.prototype.uniqueGuid = undefined;

/**
 * @member {Number} ePathwayId
 */
PathwayOutcomeDto.prototype.ePathwayId = undefined;

/**
 * @member {Number} mPathwayOutcomeId
 */
PathwayOutcomeDto.prototype.mPathwayOutcomeId = undefined;

/**
 * @member {module:model/MPathwayOutcomeDto} mPathwayOutcome
 */
PathwayOutcomeDto.prototype.mPathwayOutcome = undefined;

/**
 * @member {Array.<module:model/PathwayOutcomeAttributeDto>} ePathwayOutcomeAttribute
 */
PathwayOutcomeDto.prototype.ePathwayOutcomeAttribute = undefined;

