/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The LogInDto model module.
 * @module model/LogInDto
 * @version v3.17(PROD)
 */
export default class LogInDto {
  /**
   * Constructs a new <code>LogInDto</code>.
   * @alias module:model/LogInDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>LogInDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LogInDto} obj Optional instance to populate.
   * @return {module:model/LogInDto} The populated <code>LogInDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LogInDto();
      if (data.hasOwnProperty('contactNumber'))
        obj.contactNumber = ApiClient.convertToType(data['contactNumber'], 'String');
      if (data.hasOwnProperty('isOTPValidated'))
        obj.isOTPValidated = ApiClient.convertToType(data['isOTPValidated'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} contactNumber
 */
LogInDto.prototype.contactNumber = undefined;

/**
 * @member {Boolean} isOTPValidated
 */
LogInDto.prototype.isOTPValidated = undefined;

