/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The CandidateCertificateDto model module.
 * @module model/CandidateCertificateDto
 * @version v3.17(PROD)
 */
export default class CandidateCertificateDto {
  /**
   * Constructs a new <code>CandidateCertificateDto</code>.
   * @alias module:model/CandidateCertificateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateCertificateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateCertificateDto} obj Optional instance to populate.
   * @return {module:model/CandidateCertificateDto} The populated <code>CandidateCertificateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateCertificateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('startDate'))
        obj.startDate = ApiClient.convertToType(data['startDate'], 'Date');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'Date');
      if (data.hasOwnProperty('authority'))
        obj.authority = ApiClient.convertToType(data['authority'], 'String');
      if (data.hasOwnProperty('certificationUrl'))
        obj.certificationUrl = ApiClient.convertToType(data['certificationUrl'], 'String');
      if (data.hasOwnProperty('certificateId'))
        obj.certificateId = ApiClient.convertToType(data['certificateId'], 'Number');
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CandidateCertificateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CandidateCertificateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CandidateCertificateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} title
 */
CandidateCertificateDto.prototype.title = undefined;

/**
 * @member {Date} startDate
 */
CandidateCertificateDto.prototype.startDate = undefined;

/**
 * @member {Date} endDate
 */
CandidateCertificateDto.prototype.endDate = undefined;

/**
 * @member {String} authority
 */
CandidateCertificateDto.prototype.authority = undefined;

/**
 * @member {String} certificationUrl
 */
CandidateCertificateDto.prototype.certificationUrl = undefined;

/**
 * @member {Number} certificateId
 */
CandidateCertificateDto.prototype.certificateId = undefined;

/**
 * @member {Number} candidateId
 */
CandidateCertificateDto.prototype.candidateId = undefined;

