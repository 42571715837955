/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import InterviewStatus from './InterviewStatus';
import JDClientInterviewRoundPanelCreateDto from './JDClientInterviewRoundPanelCreateDto';

/**
 * The JdClientInterviewRoundsCreateDto model module.
 * @module model/JdClientInterviewRoundsCreateDto
 * @version v3.17(PROD)
 */
export default class JdClientInterviewRoundsCreateDto {
  /**
   * Constructs a new <code>JdClientInterviewRoundsCreateDto</code>.
   * @alias module:model/JdClientInterviewRoundsCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>JdClientInterviewRoundsCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JdClientInterviewRoundsCreateDto} obj Optional instance to populate.
   * @return {module:model/JdClientInterviewRoundsCreateDto} The populated <code>JdClientInterviewRoundsCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JdClientInterviewRoundsCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('date'))
        obj._date = ApiClient.convertToType(data['date'], 'Date');
      if (data.hasOwnProperty('status'))
        obj.status = InterviewStatus.constructFromObject(data['status']);
      if (data.hasOwnProperty('roundName'))
        obj.roundName = ApiClient.convertToType(data['roundName'], 'String');
      if (data.hasOwnProperty('remarks'))
        obj.remarks = ApiClient.convertToType(data['remarks'], 'String');
      if (data.hasOwnProperty('mode'))
        obj.mode = ApiClient.convertToType(data['mode'], 'String');
      if (data.hasOwnProperty('link'))
        obj.link = ApiClient.convertToType(data['link'], 'String');
      if (data.hasOwnProperty('addressId'))
        obj.addressId = ApiClient.convertToType(data['addressId'], 'Number');
      if (data.hasOwnProperty('time'))
        obj.time = ApiClient.convertToType(data['time'], 'Date');
      if (data.hasOwnProperty('duration'))
        obj.duration = ApiClient.convertToType(data['duration'], 'String');
      if (data.hasOwnProperty('jdClientInterviewId'))
        obj.jdClientInterviewId = ApiClient.convertToType(data['jdClientInterviewId'], 'Number');
      if (data.hasOwnProperty('jdClientInterviewRoundPanels'))
        obj.jdClientInterviewRoundPanels = ApiClient.convertToType(data['jdClientInterviewRoundPanels'], [JDClientInterviewRoundPanelCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JdClientInterviewRoundsCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
JdClientInterviewRoundsCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
JdClientInterviewRoundsCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
JdClientInterviewRoundsCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {Date} _date
 */
JdClientInterviewRoundsCreateDto.prototype._date = undefined;

/**
 * @member {module:model/InterviewStatus} status
 */
JdClientInterviewRoundsCreateDto.prototype.status = undefined;

/**
 * @member {String} roundName
 */
JdClientInterviewRoundsCreateDto.prototype.roundName = undefined;

/**
 * @member {String} remarks
 */
JdClientInterviewRoundsCreateDto.prototype.remarks = undefined;

/**
 * @member {String} mode
 */
JdClientInterviewRoundsCreateDto.prototype.mode = undefined;

/**
 * @member {String} link
 */
JdClientInterviewRoundsCreateDto.prototype.link = undefined;

/**
 * @member {Number} addressId
 */
JdClientInterviewRoundsCreateDto.prototype.addressId = undefined;

/**
 * @member {Date} time
 */
JdClientInterviewRoundsCreateDto.prototype.time = undefined;

/**
 * @member {String} duration
 */
JdClientInterviewRoundsCreateDto.prototype.duration = undefined;

/**
 * @member {Number} jdClientInterviewId
 */
JdClientInterviewRoundsCreateDto.prototype.jdClientInterviewId = undefined;

/**
 * @member {Array.<module:model/JDClientInterviewRoundPanelCreateDto>} jdClientInterviewRoundPanels
 */
JdClientInterviewRoundsCreateDto.prototype.jdClientInterviewRoundPanels = undefined;

