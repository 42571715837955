/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CandidateAndAddressDto from './CandidateAndAddressDto';
import CandidateEmailDto from './CandidateEmailDto';
import CandidatePhoneDto from './CandidatePhoneDto';
import CandidateWebsiteDto from './CandidateWebsiteDto';

/**
 * The CandidateContactDto model module.
 * @module model/CandidateContactDto
 * @version v3.17(PROD)
 */
export default class CandidateContactDto {
  /**
   * Constructs a new <code>CandidateContactDto</code>.
   * @alias module:model/CandidateContactDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateContactDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateContactDto} obj Optional instance to populate.
   * @return {module:model/CandidateContactDto} The populated <code>CandidateContactDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateContactDto();
      if (data.hasOwnProperty('candidateEmails'))
        obj.candidateEmails = ApiClient.convertToType(data['candidateEmails'], [CandidateEmailDto]);
      if (data.hasOwnProperty('candidatePhones'))
        obj.candidatePhones = ApiClient.convertToType(data['candidatePhones'], [CandidatePhoneDto]);
      if (data.hasOwnProperty('candidateWebsites'))
        obj.candidateWebsites = ApiClient.convertToType(data['candidateWebsites'], [CandidateWebsiteDto]);
      if (data.hasOwnProperty('candidateAddresses'))
        obj.candidateAddresses = ApiClient.convertToType(data['candidateAddresses'], [CandidateAndAddressDto]);
      if (data.hasOwnProperty('nationality'))
        obj.nationality = ApiClient.convertToType(data['nationality'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/CandidateEmailDto>} candidateEmails
 */
CandidateContactDto.prototype.candidateEmails = undefined;

/**
 * @member {Array.<module:model/CandidatePhoneDto>} candidatePhones
 */
CandidateContactDto.prototype.candidatePhones = undefined;

/**
 * @member {Array.<module:model/CandidateWebsiteDto>} candidateWebsites
 */
CandidateContactDto.prototype.candidateWebsites = undefined;

/**
 * @member {Array.<module:model/CandidateAndAddressDto>} candidateAddresses
 */
CandidateContactDto.prototype.candidateAddresses = undefined;

/**
 * @member {String} nationality
 */
CandidateContactDto.prototype.nationality = undefined;

