/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The ClientDashboardDto model module.
 * @module model/ClientDashboardDto
 * @version v3.17(PROD)
 */
export default class ClientDashboardDto {
  /**
   * Constructs a new <code>ClientDashboardDto</code>.
   * @alias module:model/ClientDashboardDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClientDashboardDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClientDashboardDto} obj Optional instance to populate.
   * @return {module:model/ClientDashboardDto} The populated <code>ClientDashboardDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClientDashboardDto();
      if (data.hasOwnProperty('jobOpenings'))
        obj.jobOpenings = ApiClient.convertToType(data['jobOpenings'], 'Number');
      if (data.hasOwnProperty('noOfCandidates'))
        obj.noOfCandidates = ApiClient.convertToType(data['noOfCandidates'], 'Number');
      if (data.hasOwnProperty('assessedCandidates'))
        obj.assessedCandidates = ApiClient.convertToType(data['assessedCandidates'], 'Number');
      if (data.hasOwnProperty('scheduledInterview'))
        obj.scheduledInterview = ApiClient.convertToType(data['scheduledInterview'], 'Number');
      if (data.hasOwnProperty('offered'))
        obj.offered = ApiClient.convertToType(data['offered'], 'Number');
      if (data.hasOwnProperty('hired'))
        obj.hired = ApiClient.convertToType(data['hired'], 'Number');
      if (data.hasOwnProperty('jdName'))
        obj.jdName = ApiClient.convertToType(data['jdName'], 'String');
      if (data.hasOwnProperty('jdCode'))
        obj.jdCode = ApiClient.convertToType(data['jdCode'], 'String');
      if (data.hasOwnProperty('pathwayCount'))
        obj.pathwayCount = ApiClient.convertToType(data['pathwayCount'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} jobOpenings
 */
ClientDashboardDto.prototype.jobOpenings = undefined;

/**
 * @member {Number} noOfCandidates
 */
ClientDashboardDto.prototype.noOfCandidates = undefined;

/**
 * @member {Number} assessedCandidates
 */
ClientDashboardDto.prototype.assessedCandidates = undefined;

/**
 * @member {Number} scheduledInterview
 */
ClientDashboardDto.prototype.scheduledInterview = undefined;

/**
 * @member {Number} offered
 */
ClientDashboardDto.prototype.offered = undefined;

/**
 * @member {Number} hired
 */
ClientDashboardDto.prototype.hired = undefined;

/**
 * @member {String} jdName
 */
ClientDashboardDto.prototype.jdName = undefined;

/**
 * @member {String} jdCode
 */
ClientDashboardDto.prototype.jdCode = undefined;

/**
 * @member {Number} pathwayCount
 */
ClientDashboardDto.prototype.pathwayCount = undefined;

