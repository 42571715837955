/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import MStepAttributeCompactDtoApiResponse from '../model/MStepAttributeCompactDtoApiResponse';
import MStepAttributeCompactDtoIEnumerableApiResponse from '../model/MStepAttributeCompactDtoIEnumerableApiResponse';
import MStepAttributeCompactDtoPagedListApiResponse from '../model/MStepAttributeCompactDtoPagedListApiResponse';
import MStepAttributeCreateDto from '../model/MStepAttributeCreateDto';
import MStepAttributeDtoApiResponse from '../model/MStepAttributeDtoApiResponse';
import MStepAttributeEditDto from '../model/MStepAttributeEditDto';

/**
* MStepAttribute service.
* @module api/MStepAttributeApi
* @version v3.17(PROD)
*/
export default class MStepAttributeApi {

    /**
    * Constructs a new MStepAttributeApi. 
    * @alias module:api/MStepAttributeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiMStepAttributeGet operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributeGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/MStepAttributeApi~apiMStepAttributeGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributeGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributeGetAllByPageGet operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributeGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/MStepAttributeApi~apiMStepAttributeGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributeGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributeGetAllPageByEntityIdEntityIdGet operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributeGetAllPageByEntityIdEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/MStepAttributeApi~apiMStepAttributeGetAllPageByEntityIdEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributeGetAllPageByEntityIdEntityIdGet(entityId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiMStepAttributeGetAllPageByEntityIdEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute/GetAllPageByEntityId/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributeGetAllStepAttributeByMStepIdMStepIdGet operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributeGetAllStepAttributeByMStepIdMStepIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} mStepId 
     * @param {module:api/MStepAttributeApi~apiMStepAttributeGetAllStepAttributeByMStepIdMStepIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributeGetAllStepAttributeByMStepIdMStepIdGet(mStepId, callback) {
      
      let postBody = null;
      // verify the required parameter 'mStepId' is set
      if (mStepId === undefined || mStepId === null) {
        throw new Error("Missing the required parameter 'mStepId' when calling apiMStepAttributeGetAllStepAttributeByMStepIdMStepIdGet");
      }

      let pathParams = {
        'mStepId': mStepId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute/GetAllStepAttributeByMStepId/{mStepId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributeGetByGuidGuidGet operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributeGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/MStepAttributeApi~apiMStepAttributeGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributeGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiMStepAttributeGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributeIdDelete operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributeIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/MStepAttributeApi~apiMStepAttributeIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributeIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiMStepAttributeIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributeIdGet operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributeIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/MStepAttributeApi~apiMStepAttributeIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributeIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiMStepAttributeIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributePost operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MStepAttributeCreateDto} opts.body 
     * @param {module:api/MStepAttributeApi~apiMStepAttributePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepAttributePut operation.
     * @callback moduleapi/MStepAttributeApi~apiMStepAttributePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MStepAttributeEditDto} opts.body 
     * @param {module:api/MStepAttributeApi~apiMStepAttributePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepAttributePut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepAttribute', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}