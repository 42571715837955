/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import FieldOfStudyCompactDtoApiResponse from '../model/FieldOfStudyCompactDtoApiResponse';
import FieldOfStudyCompactDtoIEnumerableApiResponse from '../model/FieldOfStudyCompactDtoIEnumerableApiResponse';
import FieldOfStudyCompactDtoPagedListApiResponse from '../model/FieldOfStudyCompactDtoPagedListApiResponse';
import FieldOfStudyCreateDto from '../model/FieldOfStudyCreateDto';
import FieldOfStudyDtoApiResponse from '../model/FieldOfStudyDtoApiResponse';
import FieldOfStudyEditDto from '../model/FieldOfStudyEditDto';

/**
* FieldOfStudy service.
* @module api/FieldOfStudyApi
* @version v3.17(PROD)
*/
export default class FieldOfStudyApi {

    /**
    * Constructs a new FieldOfStudyApi. 
    * @alias module:api/FieldOfStudyApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiFieldOfStudyGet operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFieldOfStudyGetAllByNameGet operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyGetAllByNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyGetAllByNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyGetAllByNameGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy/GetAllByName', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFieldOfStudyGetAllByPageGet operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFieldOfStudyGetByGuidGuidGet operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiFieldOfStudyGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFieldOfStudyIdDelete operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiFieldOfStudyIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFieldOfStudyIdGet operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiFieldOfStudyIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFieldOfStudyPost operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/FieldOfStudyCreateDto} opts.body 
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFieldOfStudyPut operation.
     * @callback moduleapi/FieldOfStudyApi~apiFieldOfStudyPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FieldOfStudyDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/FieldOfStudyEditDto} opts.body 
     * @param {module:api/FieldOfStudyApi~apiFieldOfStudyPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFieldOfStudyPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FieldOfStudyDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FieldOfStudy', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}