/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MPathwayAttributeDto from './MPathwayAttributeDto';

/**
 * The PathwayAttributeDto model module.
 * @module model/PathwayAttributeDto
 * @version v3.17(PROD)
 */
export default class PathwayAttributeDto {
  /**
   * Constructs a new <code>PathwayAttributeDto</code>.
   * @alias module:model/PathwayAttributeDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayAttributeDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayAttributeDto} obj Optional instance to populate.
   * @return {module:model/PathwayAttributeDto} The populated <code>PathwayAttributeDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayAttributeDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('attributeName'))
        obj.attributeName = ApiClient.convertToType(data['attributeName'], 'String');
      if (data.hasOwnProperty('attributeValue'))
        obj.attributeValue = ApiClient.convertToType(data['attributeValue'], 'String');
      if (data.hasOwnProperty('ePathwayId'))
        obj.ePathwayId = ApiClient.convertToType(data['ePathwayId'], 'Number');
      if (data.hasOwnProperty('mPathwayAttributeId'))
        obj.mPathwayAttributeId = ApiClient.convertToType(data['mPathwayAttributeId'], 'Number');
      if (data.hasOwnProperty('mPathwayAttribute'))
        obj.mPathwayAttribute = MPathwayAttributeDto.constructFromObject(data['mPathwayAttribute']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayAttributeDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
PathwayAttributeDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
PathwayAttributeDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayAttributeDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} attributeName
 */
PathwayAttributeDto.prototype.attributeName = undefined;

/**
 * @member {String} attributeValue
 */
PathwayAttributeDto.prototype.attributeValue = undefined;

/**
 * @member {Number} ePathwayId
 */
PathwayAttributeDto.prototype.ePathwayId = undefined;

/**
 * @member {Number} mPathwayAttributeId
 */
PathwayAttributeDto.prototype.mPathwayAttributeId = undefined;

/**
 * @member {module:model/MPathwayAttributeDto} mPathwayAttribute
 */
PathwayAttributeDto.prototype.mPathwayAttribute = undefined;

