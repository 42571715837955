/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import SkillType from './SkillType';

/**
 * The CandidateSkillCompactDto model module.
 * @module model/CandidateSkillCompactDto
 * @version v3.17(PROD)
 */
export default class CandidateSkillCompactDto {
  /**
   * Constructs a new <code>CandidateSkillCompactDto</code>.
   * @alias module:model/CandidateSkillCompactDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateSkillCompactDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateSkillCompactDto} obj Optional instance to populate.
   * @return {module:model/CandidateSkillCompactDto} The populated <code>CandidateSkillCompactDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateSkillCompactDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('skillType'))
        obj.skillType = SkillType.constructFromObject(data['skillType']);
      if (data.hasOwnProperty('skillId'))
        obj.skillId = ApiClient.convertToType(data['skillId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CandidateSkillCompactDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CandidateSkillCompactDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CandidateSkillCompactDto.prototype.modifiedBy = undefined;

/**
 * @member {String} name
 */
CandidateSkillCompactDto.prototype.name = undefined;

/**
 * @member {module:model/SkillType} skillType
 */
CandidateSkillCompactDto.prototype.skillType = undefined;

/**
 * @member {Number} skillId
 */
CandidateSkillCompactDto.prototype.skillId = undefined;

