/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AcceptanceType from './AcceptanceType';
import AcceptedStatus from './AcceptedStatus';
import ClientDto from './ClientDto';
import EmploymentType from './EmploymentType';
import JdAndAddressCompactDto from './JdAndAddressCompactDto';
import JdSkillDto from './JdSkillDto';
import JdSoftSkillDto from './JdSoftSkillDto';
import JdTraitDto from './JdTraitDto';
import JobProfileType from './JobProfileType';
import WorkplaceType from './WorkplaceType';

/**
 * The JobDescriptionCompactDto model module.
 * @module model/JobDescriptionCompactDto
 * @version v3.17(PROD)
 */
export default class JobDescriptionCompactDto {
  /**
   * Constructs a new <code>JobDescriptionCompactDto</code>.
   * @alias module:model/JobDescriptionCompactDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>JobDescriptionCompactDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JobDescriptionCompactDto} obj Optional instance to populate.
   * @return {module:model/JobDescriptionCompactDto} The populated <code>JobDescriptionCompactDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JobDescriptionCompactDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('slug'))
        obj.slug = ApiClient.convertToType(data['slug'], 'String');
      if (data.hasOwnProperty('date'))
        obj._date = ApiClient.convertToType(data['date'], 'Date');
      if (data.hasOwnProperty('department'))
        obj.department = ApiClient.convertToType(data['department'], 'String');
      if (data.hasOwnProperty('noOfOpenings'))
        obj.noOfOpenings = ApiClient.convertToType(data['noOfOpenings'], 'Number');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('responsibilities'))
        obj.responsibilities = ApiClient.convertToType(data['responsibilities'], 'String');
      if (data.hasOwnProperty('requirements'))
        obj.requirements = ApiClient.convertToType(data['requirements'], 'String');
      if (data.hasOwnProperty('industryPreference'))
        obj.industryPreference = ApiClient.convertToType(data['industryPreference'], 'String');
      if (data.hasOwnProperty('minimumExperienceInYears'))
        obj.minimumExperienceInYears = ApiClient.convertToType(data['minimumExperienceInYears'], 'Number');
      if (data.hasOwnProperty('maximumExperienceInYears'))
        obj.maximumExperienceInYears = ApiClient.convertToType(data['maximumExperienceInYears'], 'Number');
      if (data.hasOwnProperty('jobFunction'))
        obj.jobFunction = ApiClient.convertToType(data['jobFunction'], 'String');
      if (data.hasOwnProperty('seniorityLevel'))
        obj.seniorityLevel = ApiClient.convertToType(data['seniorityLevel'], 'String');
      if (data.hasOwnProperty('isHold'))
        obj.isHold = ApiClient.convertToType(data['isHold'], 'Boolean');
      if (data.hasOwnProperty('workplaceType'))
        obj.workplaceType = WorkplaceType.constructFromObject(data['workplaceType']);
      if (data.hasOwnProperty('employmentType'))
        obj.employmentType = EmploymentType.constructFromObject(data['employmentType']);
      if (data.hasOwnProperty('clientId'))
        obj.clientId = ApiClient.convertToType(data['clientId'], 'Number');
      if (data.hasOwnProperty('client'))
        obj.client = ClientDto.constructFromObject(data['client']);
      if (data.hasOwnProperty('degreeName'))
        obj.degreeName = ApiClient.convertToType(data['degreeName'], 'String');
      if (data.hasOwnProperty('keywords'))
        obj.keywords = ApiClient.convertToType(data['keywords'], 'String');
      if (data.hasOwnProperty('jobProfileType'))
        obj.jobProfileType = JobProfileType.constructFromObject(data['jobProfileType']);
      if (data.hasOwnProperty('minimumSalary'))
        obj.minimumSalary = ApiClient.convertToType(data['minimumSalary'], 'String');
      if (data.hasOwnProperty('maximumSalary'))
        obj.maximumSalary = ApiClient.convertToType(data['maximumSalary'], 'String');
      if (data.hasOwnProperty('currency'))
        obj.currency = ApiClient.convertToType(data['currency'], 'String');
      if (data.hasOwnProperty('acceptance'))
        obj.acceptance = AcceptanceType.constructFromObject(data['acceptance']);
      if (data.hasOwnProperty('remarks'))
        obj.remarks = ApiClient.convertToType(data['remarks'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = AcceptedStatus.constructFromObject(data['status']);
      if (data.hasOwnProperty('acceptedBy'))
        obj.acceptedBy = ApiClient.convertToType(data['acceptedBy'], 'Number');
      if (data.hasOwnProperty('jobLocation'))
        obj.jobLocation = ApiClient.convertToType(data['jobLocation'], [JdAndAddressCompactDto]);
      if (data.hasOwnProperty('jdSkills'))
        obj.jdSkills = ApiClient.convertToType(data['jdSkills'], [JdSkillDto]);
      if (data.hasOwnProperty('jdSoftSkills'))
        obj.jdSoftSkills = ApiClient.convertToType(data['jdSoftSkills'], [JdSoftSkillDto]);
      if (data.hasOwnProperty('jdTraits'))
        obj.jdTraits = ApiClient.convertToType(data['jdTraits'], [JdTraitDto]);
      if (data.hasOwnProperty('createdDate'))
        obj.createdDate = ApiClient.convertToType(data['createdDate'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JobDescriptionCompactDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
JobDescriptionCompactDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
JobDescriptionCompactDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
JobDescriptionCompactDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} code
 */
JobDescriptionCompactDto.prototype.code = undefined;

/**
 * @member {String} title
 */
JobDescriptionCompactDto.prototype.title = undefined;

/**
 * @member {String} slug
 */
JobDescriptionCompactDto.prototype.slug = undefined;

/**
 * @member {Date} _date
 */
JobDescriptionCompactDto.prototype._date = undefined;

/**
 * @member {String} department
 */
JobDescriptionCompactDto.prototype.department = undefined;

/**
 * @member {Number} noOfOpenings
 */
JobDescriptionCompactDto.prototype.noOfOpenings = undefined;

/**
 * @member {String} description
 */
JobDescriptionCompactDto.prototype.description = undefined;

/**
 * @member {String} responsibilities
 */
JobDescriptionCompactDto.prototype.responsibilities = undefined;

/**
 * @member {String} requirements
 */
JobDescriptionCompactDto.prototype.requirements = undefined;

/**
 * @member {String} industryPreference
 */
JobDescriptionCompactDto.prototype.industryPreference = undefined;

/**
 * @member {Number} minimumExperienceInYears
 */
JobDescriptionCompactDto.prototype.minimumExperienceInYears = undefined;

/**
 * @member {Number} maximumExperienceInYears
 */
JobDescriptionCompactDto.prototype.maximumExperienceInYears = undefined;

/**
 * @member {String} jobFunction
 */
JobDescriptionCompactDto.prototype.jobFunction = undefined;

/**
 * @member {String} seniorityLevel
 */
JobDescriptionCompactDto.prototype.seniorityLevel = undefined;

/**
 * @member {Boolean} isHold
 */
JobDescriptionCompactDto.prototype.isHold = undefined;

/**
 * @member {module:model/WorkplaceType} workplaceType
 */
JobDescriptionCompactDto.prototype.workplaceType = undefined;

/**
 * @member {module:model/EmploymentType} employmentType
 */
JobDescriptionCompactDto.prototype.employmentType = undefined;

/**
 * @member {Number} clientId
 */
JobDescriptionCompactDto.prototype.clientId = undefined;

/**
 * @member {module:model/ClientDto} client
 */
JobDescriptionCompactDto.prototype.client = undefined;

/**
 * @member {String} degreeName
 */
JobDescriptionCompactDto.prototype.degreeName = undefined;

/**
 * @member {String} keywords
 */
JobDescriptionCompactDto.prototype.keywords = undefined;

/**
 * @member {module:model/JobProfileType} jobProfileType
 */
JobDescriptionCompactDto.prototype.jobProfileType = undefined;

/**
 * @member {String} minimumSalary
 */
JobDescriptionCompactDto.prototype.minimumSalary = undefined;

/**
 * @member {String} maximumSalary
 */
JobDescriptionCompactDto.prototype.maximumSalary = undefined;

/**
 * @member {String} currency
 */
JobDescriptionCompactDto.prototype.currency = undefined;

/**
 * @member {module:model/AcceptanceType} acceptance
 */
JobDescriptionCompactDto.prototype.acceptance = undefined;

/**
 * @member {String} remarks
 */
JobDescriptionCompactDto.prototype.remarks = undefined;

/**
 * @member {module:model/AcceptedStatus} status
 */
JobDescriptionCompactDto.prototype.status = undefined;

/**
 * @member {Number} acceptedBy
 */
JobDescriptionCompactDto.prototype.acceptedBy = undefined;

/**
 * @member {Array.<module:model/JdAndAddressCompactDto>} jobLocation
 */
JobDescriptionCompactDto.prototype.jobLocation = undefined;

/**
 * @member {Array.<module:model/JdSkillDto>} jdSkills
 */
JobDescriptionCompactDto.prototype.jdSkills = undefined;

/**
 * @member {Array.<module:model/JdSoftSkillDto>} jdSoftSkills
 */
JobDescriptionCompactDto.prototype.jdSoftSkills = undefined;

/**
 * @member {Array.<module:model/JdTraitDto>} jdTraits
 */
JobDescriptionCompactDto.prototype.jdTraits = undefined;

/**
 * @member {Date} createdDate
 */
JobDescriptionCompactDto.prototype.createdDate = undefined;

