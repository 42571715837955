/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ProjSkill from './ProjSkill';

/**
 * The ProjectDetail model module.
 * @module model/ProjectDetail
 * @version v3.17(PROD)
 */
export default class ProjectDetail {
  /**
   * Constructs a new <code>ProjectDetail</code>.
   * @alias module:model/ProjectDetail
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ProjectDetail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProjectDetail} obj Optional instance to populate.
   * @return {module:model/ProjectDetail} The populated <code>ProjectDetail</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProjectDetail();
      if (data.hasOwnProperty('projectTitle'))
        obj.projectTitle = ApiClient.convertToType(data['projectTitle'], 'String');
      if (data.hasOwnProperty('company'))
        obj.company = ApiClient.convertToType(data['company'], 'String');
      if (data.hasOwnProperty('from'))
        obj.from = ApiClient.convertToType(data['from'], 'String');
      if (data.hasOwnProperty('to'))
        obj.to = ApiClient.convertToType(data['to'], 'String');
      if (data.hasOwnProperty('clientName'))
        obj.clientName = ApiClient.convertToType(data['clientName'], 'String');
      if (data.hasOwnProperty('skills'))
        obj.skills = ApiClient.convertToType(data['skills'], [ProjSkill]);
    }
    return obj;
  }
}

/**
 * @member {String} projectTitle
 */
ProjectDetail.prototype.projectTitle = undefined;

/**
 * @member {String} company
 */
ProjectDetail.prototype.company = undefined;

/**
 * @member {String} from
 */
ProjectDetail.prototype.from = undefined;

/**
 * @member {String} to
 */
ProjectDetail.prototype.to = undefined;

/**
 * @member {String} clientName
 */
ProjectDetail.prototype.clientName = undefined;

/**
 * @member {Array.<module:model/ProjSkill>} skills
 */
ProjectDetail.prototype.skills = undefined;

