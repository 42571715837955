/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import EmploymentType from './EmploymentType';
import JdAndAddressCompactDto from './JdAndAddressCompactDto';
import JobProfileType from './JobProfileType';
import WorkplaceType from './WorkplaceType';

/**
 * The JobDescriptionEditDto model module.
 * @module model/JobDescriptionEditDto
 * @version v3.17(PROD)
 */
export default class JobDescriptionEditDto {
  /**
   * Constructs a new <code>JobDescriptionEditDto</code>.
   * @alias module:model/JobDescriptionEditDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>JobDescriptionEditDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JobDescriptionEditDto} obj Optional instance to populate.
   * @return {module:model/JobDescriptionEditDto} The populated <code>JobDescriptionEditDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JobDescriptionEditDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('date'))
        obj._date = ApiClient.convertToType(data['date'], 'Date');
      if (data.hasOwnProperty('department'))
        obj.department = ApiClient.convertToType(data['department'], 'String');
      if (data.hasOwnProperty('noOfOpenings'))
        obj.noOfOpenings = ApiClient.convertToType(data['noOfOpenings'], 'Number');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('responsibilities'))
        obj.responsibilities = ApiClient.convertToType(data['responsibilities'], 'String');
      if (data.hasOwnProperty('requirements'))
        obj.requirements = ApiClient.convertToType(data['requirements'], 'String');
      if (data.hasOwnProperty('industryPreference'))
        obj.industryPreference = ApiClient.convertToType(data['industryPreference'], 'String');
      if (data.hasOwnProperty('minimumExperienceInYears'))
        obj.minimumExperienceInYears = ApiClient.convertToType(data['minimumExperienceInYears'], 'Number');
      if (data.hasOwnProperty('maximumExperienceInYears'))
        obj.maximumExperienceInYears = ApiClient.convertToType(data['maximumExperienceInYears'], 'Number');
      if (data.hasOwnProperty('jobFunction'))
        obj.jobFunction = ApiClient.convertToType(data['jobFunction'], 'String');
      if (data.hasOwnProperty('seniorityLevel'))
        obj.seniorityLevel = ApiClient.convertToType(data['seniorityLevel'], 'String');
      if (data.hasOwnProperty('workplaceType'))
        obj.workplaceType = WorkplaceType.constructFromObject(data['workplaceType']);
      if (data.hasOwnProperty('employmentType'))
        obj.employmentType = EmploymentType.constructFromObject(data['employmentType']);
      if (data.hasOwnProperty('clientId'))
        obj.clientId = ApiClient.convertToType(data['clientId'], 'Number');
      if (data.hasOwnProperty('degreeName'))
        obj.degreeName = ApiClient.convertToType(data['degreeName'], 'String');
      if (data.hasOwnProperty('keywords'))
        obj.keywords = ApiClient.convertToType(data['keywords'], 'String');
      if (data.hasOwnProperty('jobProfileType'))
        obj.jobProfileType = JobProfileType.constructFromObject(data['jobProfileType']);
      if (data.hasOwnProperty('minimumSalary'))
        obj.minimumSalary = ApiClient.convertToType(data['minimumSalary'], 'String');
      if (data.hasOwnProperty('maximumSalary'))
        obj.maximumSalary = ApiClient.convertToType(data['maximumSalary'], 'String');
      if (data.hasOwnProperty('currency'))
        obj.currency = ApiClient.convertToType(data['currency'], 'String');
      if (data.hasOwnProperty('jobLocation'))
        obj.jobLocation = ApiClient.convertToType(data['jobLocation'], [JdAndAddressCompactDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JobDescriptionEditDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
JobDescriptionEditDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
JobDescriptionEditDto.prototype.modifiedBy = undefined;

/**
 * @member {String} title
 */
JobDescriptionEditDto.prototype.title = undefined;

/**
 * @member {Date} _date
 */
JobDescriptionEditDto.prototype._date = undefined;

/**
 * @member {String} department
 */
JobDescriptionEditDto.prototype.department = undefined;

/**
 * @member {Number} noOfOpenings
 */
JobDescriptionEditDto.prototype.noOfOpenings = undefined;

/**
 * @member {String} description
 */
JobDescriptionEditDto.prototype.description = undefined;

/**
 * @member {String} responsibilities
 */
JobDescriptionEditDto.prototype.responsibilities = undefined;

/**
 * @member {String} requirements
 */
JobDescriptionEditDto.prototype.requirements = undefined;

/**
 * @member {String} industryPreference
 */
JobDescriptionEditDto.prototype.industryPreference = undefined;

/**
 * @member {Number} minimumExperienceInYears
 */
JobDescriptionEditDto.prototype.minimumExperienceInYears = undefined;

/**
 * @member {Number} maximumExperienceInYears
 */
JobDescriptionEditDto.prototype.maximumExperienceInYears = undefined;

/**
 * @member {String} jobFunction
 */
JobDescriptionEditDto.prototype.jobFunction = undefined;

/**
 * @member {String} seniorityLevel
 */
JobDescriptionEditDto.prototype.seniorityLevel = undefined;

/**
 * @member {module:model/WorkplaceType} workplaceType
 */
JobDescriptionEditDto.prototype.workplaceType = undefined;

/**
 * @member {module:model/EmploymentType} employmentType
 */
JobDescriptionEditDto.prototype.employmentType = undefined;

/**
 * @member {Number} clientId
 */
JobDescriptionEditDto.prototype.clientId = undefined;

/**
 * @member {String} degreeName
 */
JobDescriptionEditDto.prototype.degreeName = undefined;

/**
 * @member {String} keywords
 */
JobDescriptionEditDto.prototype.keywords = undefined;

/**
 * @member {module:model/JobProfileType} jobProfileType
 */
JobDescriptionEditDto.prototype.jobProfileType = undefined;

/**
 * @member {String} minimumSalary
 */
JobDescriptionEditDto.prototype.minimumSalary = undefined;

/**
 * @member {String} maximumSalary
 */
JobDescriptionEditDto.prototype.maximumSalary = undefined;

/**
 * @member {String} currency
 */
JobDescriptionEditDto.prototype.currency = undefined;

/**
 * @member {Array.<module:model/JdAndAddressCompactDto>} jobLocation
 */
JobDescriptionEditDto.prototype.jobLocation = undefined;

