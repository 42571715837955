/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";

/**
* Files service.
* @module api/FilesApi
* @version v3.17(PROD)
*/
export default class FilesApi {

    /**
    * Constructs a new FilesApi. 
    * @alias module:api/FilesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiFilesDeleteDelete operation.
     * @callback moduleapi/FilesApi~apiFilesDeleteDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.bucketName 
     * @param {String} opts.key 
     * @param {module:api/FilesApi~apiFilesDeleteDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiFilesDeleteDelete(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'bucketName': opts['bucketName'],'key': opts['key']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Files/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFilesGetAllGet operation.
     * @callback moduleapi/FilesApi~apiFilesGetAllGetCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.bucketName 
     * @param {String} opts.prefix 
     * @param {module:api/FilesApi~apiFilesGetAllGetCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiFilesGetAllGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'bucketName': opts['bucketName'],'prefix': opts['prefix']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Files/get-all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFilesGetByKeyGet operation.
     * @callback moduleapi/FilesApi~apiFilesGetByKeyGetCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.bucketName 
     * @param {String} opts.key 
     * @param {module:api/FilesApi~apiFilesGetByKeyGetCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiFilesGetByKeyGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'bucketName': opts['bucketName'],'key': opts['key']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Files/get-by-key', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFilesUploadPost operation.
     * @callback moduleapi/FilesApi~apiFilesUploadPostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Blob} opts.file 
     * @param {String} opts.bucketName 
     * @param {String} opts.prefix 
     * @param {module:api/FilesApi~apiFilesUploadPostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiFilesUploadPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'bucketName': opts['bucketName'],'prefix': opts['prefix']
      };
      let headerParams = {
        
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['multipart/form-data'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Files/upload', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}