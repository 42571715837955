/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PathwayAttributeCreateDto from './PathwayAttributeCreateDto';
import PathwayOutcomeCreateDto from './PathwayOutcomeCreateDto';
import PathwayStepCreateDto from './PathwayStepCreateDto';
import SkillLevel from './SkillLevel';
import StatusType from './StatusType';

/**
 * The PathwayCreateDto model module.
 * @module model/PathwayCreateDto
 * @version v3.17(PROD)
 */
export default class PathwayCreateDto {
  /**
   * Constructs a new <code>PathwayCreateDto</code>.
   * @alias module:model/PathwayCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayCreateDto} obj Optional instance to populate.
   * @return {module:model/PathwayCreateDto} The populated <code>PathwayCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = StatusType.constructFromObject(data['status']);
      if (data.hasOwnProperty('isLive'))
        obj.isLive = ApiClient.convertToType(data['isLive'], 'Boolean');
      if (data.hasOwnProperty('isChargeable'))
        obj.isChargeable = ApiClient.convertToType(data['isChargeable'], 'Boolean');
      if (data.hasOwnProperty('amount'))
        obj.amount = ApiClient.convertToType(data['amount'], 'Number');
      if (data.hasOwnProperty('skillLevel'))
        obj.skillLevel = SkillLevel.constructFromObject(data['skillLevel']);
      if (data.hasOwnProperty('mPathwayTypeId'))
        obj.mPathwayTypeId = ApiClient.convertToType(data['mPathwayTypeId'], 'Number');
      if (data.hasOwnProperty('skillId'))
        obj.skillId = ApiClient.convertToType(data['skillId'], 'Number');
      if (data.hasOwnProperty('jdId'))
        obj.jdId = ApiClient.convertToType(data['jdId'], 'Number');
      if (data.hasOwnProperty('companyId'))
        obj.companyId = ApiClient.convertToType(data['companyId'], 'Number');
      if (data.hasOwnProperty('ePathwayStep'))
        obj.ePathwayStep = ApiClient.convertToType(data['ePathwayStep'], [PathwayStepCreateDto]);
      if (data.hasOwnProperty('ePathwayAttribute'))
        obj.ePathwayAttribute = ApiClient.convertToType(data['ePathwayAttribute'], [PathwayAttributeCreateDto]);
      if (data.hasOwnProperty('ePathwayOutcome'))
        obj.ePathwayOutcome = ApiClient.convertToType(data['ePathwayOutcome'], [PathwayOutcomeCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
PathwayCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
PathwayCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} title
 */
PathwayCreateDto.prototype.title = undefined;

/**
 * @member {String} description
 */
PathwayCreateDto.prototype.description = undefined;

/**
 * @member {module:model/StatusType} status
 */
PathwayCreateDto.prototype.status = undefined;

/**
 * @member {Boolean} isLive
 */
PathwayCreateDto.prototype.isLive = undefined;

/**
 * @member {Boolean} isChargeable
 */
PathwayCreateDto.prototype.isChargeable = undefined;

/**
 * @member {Number} amount
 */
PathwayCreateDto.prototype.amount = undefined;

/**
 * @member {module:model/SkillLevel} skillLevel
 */
PathwayCreateDto.prototype.skillLevel = undefined;

/**
 * @member {Number} mPathwayTypeId
 */
PathwayCreateDto.prototype.mPathwayTypeId = undefined;

/**
 * @member {Number} skillId
 */
PathwayCreateDto.prototype.skillId = undefined;

/**
 * @member {Number} jdId
 */
PathwayCreateDto.prototype.jdId = undefined;

/**
 * @member {Number} companyId
 */
PathwayCreateDto.prototype.companyId = undefined;

/**
 * @member {Array.<module:model/PathwayStepCreateDto>} ePathwayStep
 */
PathwayCreateDto.prototype.ePathwayStep = undefined;

/**
 * @member {Array.<module:model/PathwayAttributeCreateDto>} ePathwayAttribute
 */
PathwayCreateDto.prototype.ePathwayAttribute = undefined;

/**
 * @member {Array.<module:model/PathwayOutcomeCreateDto>} ePathwayOutcome
 */
PathwayCreateDto.prototype.ePathwayOutcome = undefined;

