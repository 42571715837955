/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The CityCreateDto model module.
 * @module model/CityCreateDto
 * @version v3.17(PROD)
 */
export default class CityCreateDto {
  /**
   * Constructs a new <code>CityCreateDto</code>.
   * @alias module:model/CityCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CityCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CityCreateDto} obj Optional instance to populate.
   * @return {module:model/CityCreateDto} The populated <code>CityCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CityCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('pinCode'))
        obj.pinCode = ApiClient.convertToType(data['pinCode'], 'Number');
      if (data.hasOwnProperty('stdCode'))
        obj.stdCode = ApiClient.convertToType(data['stdCode'], 'Number');
      if (data.hasOwnProperty('stateId'))
        obj.stateId = ApiClient.convertToType(data['stateId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CityCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CityCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CityCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
CityCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
CityCreateDto.prototype.name = undefined;

/**
 * @member {String} code
 */
CityCreateDto.prototype.code = undefined;

/**
 * @member {Number} pinCode
 */
CityCreateDto.prototype.pinCode = undefined;

/**
 * @member {Number} stdCode
 */
CityCreateDto.prototype.stdCode = undefined;

/**
 * @member {Number} stateId
 */
CityCreateDto.prototype.stateId = undefined;

