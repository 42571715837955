/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The RSSoftSkill model module.
 * @module model/RSSoftSkill
 * @version v3.17(PROD)
 */
export default class RSSoftSkill {
  /**
   * Constructs a new <code>RSSoftSkill</code>.
   * @alias module:model/RSSoftSkill
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RSSoftSkill</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RSSoftSkill} obj Optional instance to populate.
   * @return {module:model/RSSoftSkill} The populated <code>RSSoftSkill</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RSSoftSkill();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('skillName'))
        obj.skillName = ApiClient.convertToType(data['skillName'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
RSSoftSkill.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
RSSoftSkill.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
RSSoftSkill.prototype.modifiedBy = undefined;

/**
 * @member {String} skillName
 */
RSSoftSkill.prototype.skillName = undefined;

