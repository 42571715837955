/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import AssociatedEmployerCompactDtoIEnumerableApiResponse from '../model/AssociatedEmployerCompactDtoIEnumerableApiResponse';
import CandidateEmployerCompactDtoApiResponse from '../model/CandidateEmployerCompactDtoApiResponse';
import CandidateEmployerCreateDto from '../model/CandidateEmployerCreateDto';
import CandidateEmployerDtoApiResponse from '../model/CandidateEmployerDtoApiResponse';
import CandidateEmployerDtoIEnumerableApiResponse from '../model/CandidateEmployerDtoIEnumerableApiResponse';
import CandidateEmployerEditDto from '../model/CandidateEmployerEditDto';

/**
* CandidateEmployer service.
* @module api/CandidateEmployerApi
* @version v3.17(PROD)
*/
export default class CandidateEmployerApi {

    /**
    * Constructs a new CandidateEmployerApi. 
    * @alias module:api/CandidateEmployerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiCandidateEmployerGetAllEmployerByCandidateIdcandidateIdGet operation.
     * @callback moduleapi/CandidateEmployerApi~apiCandidateEmployerGetAllEmployerByCandidateIdcandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateEmployerDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {module:api/CandidateEmployerApi~apiCandidateEmployerGetAllEmployerByCandidateIdcandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateEmployerGetAllEmployerByCandidateIdcandidateIdGet(candidateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateEmployerGetAllEmployerByCandidateIdcandidateIdGet");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateEmployerDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateEmployer/GetAllEmployerByCandidateId{candidateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateEmployerGetEmployerCompanyByCandidateIdcandidateIdGet operation.
     * @callback moduleapi/CandidateEmployerApi~apiCandidateEmployerGetEmployerCompanyByCandidateIdcandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AssociatedEmployerCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {module:api/CandidateEmployerApi~apiCandidateEmployerGetEmployerCompanyByCandidateIdcandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateEmployerGetEmployerCompanyByCandidateIdcandidateIdGet(candidateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateEmployerGetEmployerCompanyByCandidateIdcandidateIdGet");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AssociatedEmployerCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateEmployer/GetEmployerCompanyByCandidateId{candidateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateEmployerIdDelete operation.
     * @callback moduleapi/CandidateEmployerApi~apiCandidateEmployerIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateEmployerCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateEmployerApi~apiCandidateEmployerIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateEmployerIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateEmployerIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateEmployerCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateEmployer/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateEmployerIdGet operation.
     * @callback moduleapi/CandidateEmployerApi~apiCandidateEmployerIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateEmployerDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateEmployerApi~apiCandidateEmployerIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateEmployerIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateEmployerIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateEmployerDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateEmployer/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateEmployerPost operation.
     * @callback moduleapi/CandidateEmployerApi~apiCandidateEmployerPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateEmployerDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateEmployerCreateDto} opts.body 
     * @param {module:api/CandidateEmployerApi~apiCandidateEmployerPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateEmployerPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateEmployerDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateEmployer', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateEmployerUpdateEmployerDetailsPut operation.
     * @callback moduleapi/CandidateEmployerApi~apiCandidateEmployerUpdateEmployerDetailsPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateEmployerDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateEmployerEditDto} opts.body 
     * @param {module:api/CandidateEmployerApi~apiCandidateEmployerUpdateEmployerDetailsPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateEmployerUpdateEmployerDetailsPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateEmployerDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateEmployer/UpdateEmployerDetails', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}