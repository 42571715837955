/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MStepAttributeDto from './MStepAttributeDto';
import MStepOutcomeCommunicationDto from './MStepOutcomeCommunicationDto';
import MStepOutcomeDto from './MStepOutcomeDto';
import MStepTextDto from './MStepTextDto';
import StatusType from './StatusType';

/**
 * The MStepCompactDto model module.
 * @module model/MStepCompactDto
 * @version v3.17(PROD)
 */
export default class MStepCompactDto {
  /**
   * Constructs a new <code>MStepCompactDto</code>.
   * @alias module:model/MStepCompactDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MStepCompactDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MStepCompactDto} obj Optional instance to populate.
   * @return {module:model/MStepCompactDto} The populated <code>MStepCompactDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MStepCompactDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('isMandate'))
        obj.isMandate = ApiClient.convertToType(data['isMandate'], 'Boolean');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = StatusType.constructFromObject(data['status']);
      if (data.hasOwnProperty('isInternal'))
        obj.isInternal = ApiClient.convertToType(data['isInternal'], 'Boolean');
      if (data.hasOwnProperty('mStepText'))
        obj.mStepText = ApiClient.convertToType(data['mStepText'], [MStepTextDto]);
      if (data.hasOwnProperty('mStepAttribute'))
        obj.mStepAttribute = ApiClient.convertToType(data['mStepAttribute'], [MStepAttributeDto]);
      if (data.hasOwnProperty('mStepOutcome'))
        obj.mStepOutcome = ApiClient.convertToType(data['mStepOutcome'], [MStepOutcomeDto]);
      if (data.hasOwnProperty('mStepOutcomeCommunication'))
        obj.mStepOutcomeCommunication = ApiClient.convertToType(data['mStepOutcomeCommunication'], [MStepOutcomeCommunicationDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
MStepCompactDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
MStepCompactDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
MStepCompactDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
MStepCompactDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
MStepCompactDto.prototype.name = undefined;

/**
 * @member {Boolean} isMandate
 */
MStepCompactDto.prototype.isMandate = undefined;

/**
 * @member {String} description
 */
MStepCompactDto.prototype.description = undefined;

/**
 * @member {module:model/StatusType} status
 */
MStepCompactDto.prototype.status = undefined;

/**
 * @member {Boolean} isInternal
 */
MStepCompactDto.prototype.isInternal = undefined;

/**
 * @member {Array.<module:model/MStepTextDto>} mStepText
 */
MStepCompactDto.prototype.mStepText = undefined;

/**
 * @member {Array.<module:model/MStepAttributeDto>} mStepAttribute
 */
MStepCompactDto.prototype.mStepAttribute = undefined;

/**
 * @member {Array.<module:model/MStepOutcomeDto>} mStepOutcome
 */
MStepCompactDto.prototype.mStepOutcome = undefined;

/**
 * @member {Array.<module:model/MStepOutcomeCommunicationDto>} mStepOutcomeCommunication
 */
MStepCompactDto.prototype.mStepOutcomeCommunication = undefined;

