/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import DocumentDto from './DocumentDto';

/**
 * The ClientContractDto model module.
 * @module model/ClientContractDto
 * @version v3.17(PROD)
 */
export default class ClientContractDto {
  /**
   * Constructs a new <code>ClientContractDto</code>.
   * @alias module:model/ClientContractDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClientContractDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClientContractDto} obj Optional instance to populate.
   * @return {module:model/ClientContractDto} The populated <code>ClientContractDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClientContractDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('startDate'))
        obj.startDate = ApiClient.convertToType(data['startDate'], 'Date');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'Date');
      if (data.hasOwnProperty('approvedBy'))
        obj.approvedBy = ApiClient.convertToType(data['approvedBy'], 'String');
      if (data.hasOwnProperty('approvedByUserId'))
        obj.approvedByUserId = ApiClient.convertToType(data['approvedByUserId'], 'Number');
      if (data.hasOwnProperty('createdByUserId'))
        obj.createdByUserId = ApiClient.convertToType(data['createdByUserId'], 'Number');
      if (data.hasOwnProperty('dateOfInvoice'))
        obj.dateOfInvoice = ApiClient.convertToType(data['dateOfInvoice'], 'Date');
      if (data.hasOwnProperty('contractDocumentId'))
        obj.contractDocumentId = ApiClient.convertToType(data['contractDocumentId'], 'Number');
      if (data.hasOwnProperty('contractDocument'))
        obj.contractDocument = DocumentDto.constructFromObject(data['contractDocument']);
      if (data.hasOwnProperty('clientId'))
        obj.clientId = ApiClient.convertToType(data['clientId'], 'Number');
      if (data.hasOwnProperty('createdDate'))
        obj.createdDate = ApiClient.convertToType(data['createdDate'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ClientContractDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
ClientContractDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
ClientContractDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
ClientContractDto.prototype.uniqueGuid = undefined;

/**
 * @member {Date} startDate
 */
ClientContractDto.prototype.startDate = undefined;

/**
 * @member {Date} endDate
 */
ClientContractDto.prototype.endDate = undefined;

/**
 * @member {String} approvedBy
 */
ClientContractDto.prototype.approvedBy = undefined;

/**
 * @member {Number} approvedByUserId
 */
ClientContractDto.prototype.approvedByUserId = undefined;

/**
 * @member {Number} createdByUserId
 */
ClientContractDto.prototype.createdByUserId = undefined;

/**
 * @member {Date} dateOfInvoice
 */
ClientContractDto.prototype.dateOfInvoice = undefined;

/**
 * @member {Number} contractDocumentId
 */
ClientContractDto.prototype.contractDocumentId = undefined;

/**
 * @member {module:model/DocumentDto} contractDocument
 */
ClientContractDto.prototype.contractDocument = undefined;

/**
 * @member {Number} clientId
 */
ClientContractDto.prototype.clientId = undefined;

/**
 * @member {Date} createdDate
 */
ClientContractDto.prototype.createdDate = undefined;

