/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CandidateDto from './CandidateDto';
import ClientDto from './ClientDto';
import InterviewStatus from './InterviewStatus';
import JdAndCandidateStatusDto from './JdAndCandidateStatusDto';
import JdClientInterviewRoundsDto from './JdClientInterviewRoundsDto';
import JobDescriptionDto from './JobDescriptionDto';
import ProgressiveStatus from './ProgressiveStatus';

/**
 * The JdClientInterviewDto model module.
 * @module model/JdClientInterviewDto
 * @version v3.17(PROD)
 */
export default class JdClientInterviewDto {
  /**
   * Constructs a new <code>JdClientInterviewDto</code>.
   * @alias module:model/JdClientInterviewDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>JdClientInterviewDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JdClientInterviewDto} obj Optional instance to populate.
   * @return {module:model/JdClientInterviewDto} The populated <code>JdClientInterviewDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JdClientInterviewDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = InterviewStatus.constructFromObject(data['status']);
      if (data.hasOwnProperty('progressiveStatus'))
        obj.progressiveStatus = ProgressiveStatus.constructFromObject(data['progressiveStatus']);
      if (data.hasOwnProperty('clientId'))
        obj.clientId = ApiClient.convertToType(data['clientId'], 'Number');
      if (data.hasOwnProperty('client'))
        obj.client = ClientDto.constructFromObject(data['client']);
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
      if (data.hasOwnProperty('candidate'))
        obj.candidate = CandidateDto.constructFromObject(data['candidate']);
      if (data.hasOwnProperty('jobDescriptionId'))
        obj.jobDescriptionId = ApiClient.convertToType(data['jobDescriptionId'], 'Number');
      if (data.hasOwnProperty('jobDescription'))
        obj.jobDescription = JobDescriptionDto.constructFromObject(data['jobDescription']);
      if (data.hasOwnProperty('jdAndCandidateStatusId'))
        obj.jdAndCandidateStatusId = ApiClient.convertToType(data['jdAndCandidateStatusId'], 'Number');
      if (data.hasOwnProperty('jdAndCandidateStatus'))
        obj.jdAndCandidateStatus = JdAndCandidateStatusDto.constructFromObject(data['jdAndCandidateStatus']);
      if (data.hasOwnProperty('jdClientInterviewRounds'))
        obj.jdClientInterviewRounds = ApiClient.convertToType(data['jdClientInterviewRounds'], [JdClientInterviewRoundsDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JdClientInterviewDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
JdClientInterviewDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
JdClientInterviewDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
JdClientInterviewDto.prototype.uniqueGuid = undefined;

/**
 * @member {module:model/InterviewStatus} status
 */
JdClientInterviewDto.prototype.status = undefined;

/**
 * @member {module:model/ProgressiveStatus} progressiveStatus
 */
JdClientInterviewDto.prototype.progressiveStatus = undefined;

/**
 * @member {Number} clientId
 */
JdClientInterviewDto.prototype.clientId = undefined;

/**
 * @member {module:model/ClientDto} client
 */
JdClientInterviewDto.prototype.client = undefined;

/**
 * @member {Number} candidateId
 */
JdClientInterviewDto.prototype.candidateId = undefined;

/**
 * @member {module:model/CandidateDto} candidate
 */
JdClientInterviewDto.prototype.candidate = undefined;

/**
 * @member {Number} jobDescriptionId
 */
JdClientInterviewDto.prototype.jobDescriptionId = undefined;

/**
 * @member {module:model/JobDescriptionDto} jobDescription
 */
JdClientInterviewDto.prototype.jobDescription = undefined;

/**
 * @member {Number} jdAndCandidateStatusId
 */
JdClientInterviewDto.prototype.jdAndCandidateStatusId = undefined;

/**
 * @member {module:model/JdAndCandidateStatusDto} jdAndCandidateStatus
 */
JdClientInterviewDto.prototype.jdAndCandidateStatus = undefined;

/**
 * @member {Array.<module:model/JdClientInterviewRoundsDto>} jdClientInterviewRounds
 */
JdClientInterviewDto.prototype.jdClientInterviewRounds = undefined;

