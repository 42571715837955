/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import RSTechnicalSkillPlatform from './RSTechnicalSkillPlatform';

/**
 * The RSTechnicalSkill model module.
 * @module model/RSTechnicalSkill
 * @version v3.17(PROD)
 */
export default class RSTechnicalSkill {
  /**
   * Constructs a new <code>RSTechnicalSkill</code>.
   * @alias module:model/RSTechnicalSkill
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RSTechnicalSkill</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RSTechnicalSkill} obj Optional instance to populate.
   * @return {module:model/RSTechnicalSkill} The populated <code>RSTechnicalSkill</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RSTechnicalSkill();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('skillName'))
        obj.skillName = ApiClient.convertToType(data['skillName'], 'String');
      if (data.hasOwnProperty('experienceInMonths'))
        obj.experienceInMonths = ApiClient.convertToType(data['experienceInMonths'], 'String');
      if (data.hasOwnProperty('technicalSkillPlatform'))
        obj.technicalSkillPlatform = ApiClient.convertToType(data['technicalSkillPlatform'], [RSTechnicalSkillPlatform]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
RSTechnicalSkill.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
RSTechnicalSkill.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
RSTechnicalSkill.prototype.modifiedBy = undefined;

/**
 * @member {String} skillName
 */
RSTechnicalSkill.prototype.skillName = undefined;

/**
 * @member {String} experienceInMonths
 */
RSTechnicalSkill.prototype.experienceInMonths = undefined;

/**
 * @member {Array.<module:model/RSTechnicalSkillPlatform>} technicalSkillPlatform
 */
RSTechnicalSkill.prototype.technicalSkillPlatform = undefined;

