/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The MPathwayType model module.
 * @module model/MPathwayType
 * @version v3.17(PROD)
 */
export default class MPathwayType {
  /**
   * Constructs a new <code>MPathwayType</code>.
   * @alias module:model/MPathwayType
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MPathwayType</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MPathwayType} obj Optional instance to populate.
   * @return {module:model/MPathwayType} The populated <code>MPathwayType</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MPathwayType();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('isDeleted'))
        obj.isDeleted = ApiClient.convertToType(data['isDeleted'], 'Boolean');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('createdDate'))
        obj.createdDate = ApiClient.convertToType(data['createdDate'], 'Date');
      if (data.hasOwnProperty('istCreatedDate'))
        obj.istCreatedDate = ApiClient.convertToType(data['istCreatedDate'], 'Date');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('modifiedDate'))
        obj.modifiedDate = ApiClient.convertToType(data['modifiedDate'], 'Date');
      if (data.hasOwnProperty('istModifiedDate'))
        obj.istModifiedDate = ApiClient.convertToType(data['istModifiedDate'], 'Date');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
MPathwayType.prototype.id = undefined;

/**
 * @member {Boolean} isDeleted
 */
MPathwayType.prototype.isDeleted = undefined;

/**
 * @member {Number} createdBy
 */
MPathwayType.prototype.createdBy = undefined;

/**
 * @member {Date} createdDate
 */
MPathwayType.prototype.createdDate = undefined;

/**
 * @member {Date} istCreatedDate
 */
MPathwayType.prototype.istCreatedDate = undefined;

/**
 * @member {Number} modifiedBy
 */
MPathwayType.prototype.modifiedBy = undefined;

/**
 * @member {Date} modifiedDate
 */
MPathwayType.prototype.modifiedDate = undefined;

/**
 * @member {Date} istModifiedDate
 */
MPathwayType.prototype.istModifiedDate = undefined;

/**
 * @member {String} uniqueGuid
 */
MPathwayType.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
MPathwayType.prototype.name = undefined;

