/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import CandidateCompactSkillPlatformDtoIEnumerableApiResponse from '../model/CandidateCompactSkillPlatformDtoIEnumerableApiResponse';
import CandidateNonTechnicalSkillCreateDto from '../model/CandidateNonTechnicalSkillCreateDto';
import CandidateSkillCompactDtoApiResponse from '../model/CandidateSkillCompactDtoApiResponse';
import CandidateSkillCreateDto from '../model/CandidateSkillCreateDto';
import CandidateSkillDtoApiResponse from '../model/CandidateSkillDtoApiResponse';
import CandidateSkillDtoIEnumerableApiResponse from '../model/CandidateSkillDtoIEnumerableApiResponse';
import CandidateSkillEditDto from '../model/CandidateSkillEditDto';
import SkillType from '../model/SkillType';

/**
* CandidateSkill service.
* @module api/CandidateSkillApi
* @version v3.17(PROD)
*/
export default class CandidateSkillApi {

    /**
    * Constructs a new CandidateSkillApi. 
    * @alias module:api/CandidateSkillApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiCandidateSkillGetAllCandidateSkillPlatformCandidateIdGet operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillGetAllCandidateSkillPlatformCandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateCompactSkillPlatformDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {module:api/CandidateSkillApi~apiCandidateSkillGetAllCandidateSkillPlatformCandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillGetAllCandidateSkillPlatformCandidateIdGet(candidateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateSkillGetAllCandidateSkillPlatformCandidateIdGet");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateCompactSkillPlatformDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill/GetAllCandidateSkillPlatform/{candidateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateSkillGetAllDetailsByCandidateIdCandidateIdGet operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillGetAllDetailsByCandidateIdCandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {module:api/CandidateSkillApi~apiCandidateSkillGetAllDetailsByCandidateIdCandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillGetAllDetailsByCandidateIdCandidateIdGet(candidateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateSkillGetAllDetailsByCandidateIdCandidateIdGet");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill/GetAllDetailsByCandidateId/{candidateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateSkillGetAllFilterByCandidateIdCandidateIdGet operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillGetAllFilterByCandidateIdCandidateIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SkillType} opts.skillType   1 &#x3D; TechnicalSkill  2 &#x3D; SoftSkill  3 &#x3D; Expertise  4 &#x3D; Other
     * @param {module:api/CandidateSkillApi~apiCandidateSkillGetAllFilterByCandidateIdCandidateIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillGetAllFilterByCandidateIdCandidateIdGet(candidateId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateSkillGetAllFilterByCandidateIdCandidateIdGet");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        'skillType': opts['skillType']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill/GetAllFilterByCandidateId/{candidateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateSkillIdDelete operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateSkillApi~apiCandidateSkillIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateSkillIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateSkillIdGet operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateSkillApi~apiCandidateSkillIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateSkillIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateSkillPost operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateSkillCreateDto} opts.body 
     * @param {module:api/CandidateSkillApi~apiCandidateSkillPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateSkillPut operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateSkillEditDto} opts.body 
     * @param {module:api/CandidateSkillApi~apiCandidateSkillPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateSkillUpdateNonTechnicalSkillsCandidateIdPost operation.
     * @callback moduleapi/CandidateSkillApi~apiCandidateSkillUpdateNonTechnicalSkillsCandidateIdPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateSkillDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateId 
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CandidateNonTechnicalSkillCreateDto>} opts.body 
     * @param {module:model/SkillType} opts.skillType   1 &#x3D; TechnicalSkill  2 &#x3D; SoftSkill  3 &#x3D; Expertise  4 &#x3D; Other
     * @param {module:api/CandidateSkillApi~apiCandidateSkillUpdateNonTechnicalSkillsCandidateIdPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateSkillUpdateNonTechnicalSkillsCandidateIdPost(candidateId, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'candidateId' is set
      if (candidateId === undefined || candidateId === null) {
        throw new Error("Missing the required parameter 'candidateId' when calling apiCandidateSkillUpdateNonTechnicalSkillsCandidateIdPost");
      }

      let pathParams = {
        'candidateId': candidateId
      };
      let queryParams = {
        'skillType': opts['skillType']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateSkillDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateSkill/UpdateNonTechnicalSkills/{candidateId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}