/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import ContentCompactDtoApiResponse from '../model/ContentCompactDtoApiResponse';
import ContentCompactDtoIEnumerableApiResponse from '../model/ContentCompactDtoIEnumerableApiResponse';
import ContentCompactDtoPagedListApiResponse from '../model/ContentCompactDtoPagedListApiResponse';
import ContentDtoApiResponse from '../model/ContentDtoApiResponse';

/**
* Content service.
* @module api/ContentApi
* @version v3.17(PROD)
*/
export default class ContentApi {

    /**
    * Constructs a new ContentApi. 
    * @alias module:api/ContentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiContentGet operation.
     * @callback moduleapi/ContentApi~apiContentGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ContentApi~apiContentGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/Content', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentGetAllByEntityIdEntityIdGet operation.
     * @callback moduleapi/ContentApi~apiContentGetAllByEntityIdEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {module:api/ContentApi~apiContentGetAllByEntityIdEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentGetAllByEntityIdEntityIdGet(entityId, callback) {
      
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiContentGetAllByEntityIdEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/Content/GetAllByEntityId/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentGetAllByNameEntityIdGet operation.
     * @callback moduleapi/ContentApi~apiContentGetAllByNameEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/ContentApi~apiContentGetAllByNameEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentGetAllByNameEntityIdGet(entityId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiContentGetAllByNameEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/Content/GetAllByName/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentGetAllByNameGet operation.
     * @callback moduleapi/ContentApi~apiContentGetAllByNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/ContentApi~apiContentGetAllByNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentGetAllByNameGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/Content/GetAllByName', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentGetAllByPageGet operation.
     * @callback moduleapi/ContentApi~apiContentGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/ContentApi~apiContentGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/Content/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentGetAllPageByEntityGuidEntityGuidGet operation.
     * @callback moduleapi/ContentApi~apiContentGetAllPageByEntityGuidEntityGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} entityGuid 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/ContentApi~apiContentGetAllPageByEntityGuidEntityGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentGetAllPageByEntityGuidEntityGuidGet(entityGuid, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'entityGuid' is set
      if (entityGuid === undefined || entityGuid === null) {
        throw new Error("Missing the required parameter 'entityGuid' when calling apiContentGetAllPageByEntityGuidEntityGuidGet");
      }

      let pathParams = {
        'entityGuid': entityGuid
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/Content/GetAllPageByEntityGuid/{entityGuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentGetByGuidGuidGet operation.
     * @callback moduleapi/ContentApi~apiContentGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/ContentApi~apiContentGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiContentGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Content/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentIdDelete operation.
     * @callback moduleapi/ContentApi~apiContentIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ContentApi~apiContentIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiContentIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Content/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentIdGet operation.
     * @callback moduleapi/ContentApi~apiContentIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ContentApi~apiContentIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiContentIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Content/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentPost operation.
     * @callback moduleapi/ContentApi~apiContentPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {String} opts.type 
     * @param {String} opts.description 
     * @param {Blob} opts.uploadedFile 
     * @param {Number} opts.courseId 
     * @param {String} opts.uniqueGuid 
     * @param {Number} opts.id 
     * @param {Number} opts.createdBy 
     * @param {Number} opts.modifiedBy 
     * @param {module:api/ContentApi~apiContentPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'Name': opts['name'],'Type': opts['type'],'Description': opts['description'],'uploadedFile': opts['uploadedFile'],'CourseId': opts['courseId'],'UniqueGuid': opts['uniqueGuid'],'Id': opts['id'],'CreatedBy': opts['createdBy'],'ModifiedBy': opts['modifiedBy']
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Content', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiContentPut operation.
     * @callback moduleapi/ContentApi~apiContentPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ContentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {String} opts.type 
     * @param {String} opts.description 
     * @param {Blob} opts.uploadedFile 
     * @param {Number} opts.courseId 
     * @param {Number} opts.id 
     * @param {Number} opts.createdBy 
     * @param {Number} opts.modifiedBy 
     * @param {module:api/ContentApi~apiContentPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiContentPut(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'Name': opts['name'],'Type': opts['type'],'Description': opts['description'],'uploadedFile': opts['uploadedFile'],'CourseId': opts['courseId'],'Id': opts['id'],'CreatedBy': opts['createdBy'],'ModifiedBy': opts['modifiedBy']
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ContentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/Content', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}