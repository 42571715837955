/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CompanyType from './CompanyType';

/**
 * The CompanyDto model module.
 * @module model/CompanyDto
 * @version v3.17(PROD)
 */
export default class CompanyDto {
  /**
   * Constructs a new <code>CompanyDto</code>.
   * @alias module:model/CompanyDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CompanyDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CompanyDto} obj Optional instance to populate.
   * @return {module:model/CompanyDto} The populated <code>CompanyDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CompanyDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('companySize'))
        obj.companySize = ApiClient.convertToType(data['companySize'], 'Number');
      if (data.hasOwnProperty('companyType'))
        obj.companyType = CompanyType.constructFromObject(data['companyType']);
      if (data.hasOwnProperty('isPremium'))
        obj.isPremium = ApiClient.convertToType(data['isPremium'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CompanyDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CompanyDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CompanyDto.prototype.modifiedBy = undefined;

/**
 * @member {String} uniqueGuid
 */
CompanyDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
CompanyDto.prototype.name = undefined;

/**
 * @member {Number} companySize
 */
CompanyDto.prototype.companySize = undefined;

/**
 * @member {module:model/CompanyType} companyType
 */
CompanyDto.prototype.companyType = undefined;

/**
 * @member {Boolean} isPremium
 */
CompanyDto.prototype.isPremium = undefined;

