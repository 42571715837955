/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CandidateProjectsCompactDto from './CandidateProjectsCompactDto';
import PageResult from './PageResult';

/**
 * The CandidateProjectsCompactDtoApiResponse model module.
 * @module model/CandidateProjectsCompactDtoApiResponse
 * @version v3.17(PROD)
 */
export default class CandidateProjectsCompactDtoApiResponse {
  /**
   * Constructs a new <code>CandidateProjectsCompactDtoApiResponse</code>.
   * @alias module:model/CandidateProjectsCompactDtoApiResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateProjectsCompactDtoApiResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateProjectsCompactDtoApiResponse} obj Optional instance to populate.
   * @return {module:model/CandidateProjectsCompactDtoApiResponse} The populated <code>CandidateProjectsCompactDtoApiResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateProjectsCompactDtoApiResponse();
      if (data.hasOwnProperty('message'))
        obj.message = ApiClient.convertToType(data['message'], 'String');
      if (data.hasOwnProperty('messageType'))
        obj.messageType = ApiClient.convertToType(data['messageType'], 'String');
      if (data.hasOwnProperty('isSuccess'))
        obj.isSuccess = ApiClient.convertToType(data['isSuccess'], 'Boolean');
      if (data.hasOwnProperty('pageResult'))
        obj.pageResult = PageResult.constructFromObject(data['pageResult']);
      if (data.hasOwnProperty('result'))
        obj.result = CandidateProjectsCompactDto.constructFromObject(data['result']);
      if (data.hasOwnProperty('errors'))
        obj.errors = ApiClient.convertToType(data['errors'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {String} message
 */
CandidateProjectsCompactDtoApiResponse.prototype.message = undefined;

/**
 * @member {String} messageType
 */
CandidateProjectsCompactDtoApiResponse.prototype.messageType = undefined;

/**
 * @member {Boolean} isSuccess
 */
CandidateProjectsCompactDtoApiResponse.prototype.isSuccess = undefined;

/**
 * @member {module:model/PageResult} pageResult
 */
CandidateProjectsCompactDtoApiResponse.prototype.pageResult = undefined;

/**
 * @member {module:model/CandidateProjectsCompactDto} result
 */
CandidateProjectsCompactDtoApiResponse.prototype.result = undefined;

/**
 * @member {Array.<String>} errors
 */
CandidateProjectsCompactDtoApiResponse.prototype.errors = undefined;

