/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";
import CompanyAliasCompactDtoApiResponse from '../model/CompanyAliasCompactDtoApiResponse';
import CompanyAliasCompactDtoIEnumerableApiResponse from '../model/CompanyAliasCompactDtoIEnumerableApiResponse';
import CompanyAliasCompactDtoPagedListApiResponse from '../model/CompanyAliasCompactDtoPagedListApiResponse';
import CompanyAliasCreateDto from '../model/CompanyAliasCreateDto';
import CompanyAliasDtoApiResponse from '../model/CompanyAliasDtoApiResponse';
import CompanyAliasEditDto from '../model/CompanyAliasEditDto';

/**
* CompanyAlias service.
* @module api/CompanyAliasApi
* @version v3.17(PROD)
*/
export default class CompanyAliasApi {

    /**
    * Constructs a new CompanyAliasApi. 
    * @alias module:api/CompanyAliasApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiCompanyAliasGet operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CompanyAliasApi~apiCompanyAliasGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompanyAliasGetAllByEntityIdEntityIdGet operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasGetAllByEntityIdEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {module:api/CompanyAliasApi~apiCompanyAliasGetAllByEntityIdEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasGetAllByEntityIdEntityIdGet(entityId, callback) {
      
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiCompanyAliasGetAllByEntityIdEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias/GetAllByEntityId/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompanyAliasGetAllByPageGet operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/CompanyAliasApi~apiCompanyAliasGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompanyAliasGetByGuidGuidGet operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/CompanyAliasApi~apiCompanyAliasGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiCompanyAliasGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompanyAliasIdDelete operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CompanyAliasApi~apiCompanyAliasIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCompanyAliasIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompanyAliasIdGet operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CompanyAliasApi~apiCompanyAliasIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCompanyAliasIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompanyAliasPost operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyAliasCreateDto} opts.body 
     * @param {module:api/CompanyAliasApi~apiCompanyAliasPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompanyAliasPut operation.
     * @callback moduleapi/CompanyAliasApi~apiCompanyAliasPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyAliasEditDto} opts.body 
     * @param {module:api/CompanyAliasApi~apiCompanyAliasPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCompanyAliasPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CompanyAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CompanyAlias', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}