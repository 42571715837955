/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CandidateSkillPlatformCreateDto from './CandidateSkillPlatformCreateDto';
import SkillType from './SkillType';

/**
 * The CandidateSkillCreateDto model module.
 * @module model/CandidateSkillCreateDto
 * @version v3.17(PROD)
 */
export default class CandidateSkillCreateDto {
  /**
   * Constructs a new <code>CandidateSkillCreateDto</code>.
   * @alias module:model/CandidateSkillCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateSkillCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateSkillCreateDto} obj Optional instance to populate.
   * @return {module:model/CandidateSkillCreateDto} The populated <code>CandidateSkillCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateSkillCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('skillType'))
        obj.skillType = SkillType.constructFromObject(data['skillType']);
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
      if (data.hasOwnProperty('skillId'))
        obj.skillId = ApiClient.convertToType(data['skillId'], 'Number');
      if (data.hasOwnProperty('skillPlatforms'))
        obj.skillPlatforms = ApiClient.convertToType(data['skillPlatforms'], [CandidateSkillPlatformCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CandidateSkillCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
CandidateSkillCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
CandidateSkillCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} name
 */
CandidateSkillCreateDto.prototype.name = undefined;

/**
 * @member {module:model/SkillType} skillType
 */
CandidateSkillCreateDto.prototype.skillType = undefined;

/**
 * @member {Number} candidateId
 */
CandidateSkillCreateDto.prototype.candidateId = undefined;

/**
 * @member {Number} skillId
 */
CandidateSkillCreateDto.prototype.skillId = undefined;

/**
 * @member {Array.<module:model/CandidateSkillPlatformCreateDto>} skillPlatforms
 */
CandidateSkillCreateDto.prototype.skillPlatforms = undefined;

