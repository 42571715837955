/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import EmploymentStatusType from './EmploymentStatusType';
import GenderType from './GenderType';

/**
 * The CandidateViewDto model module.
 * @module model/CandidateViewDto
 * @version v3.17(PROD)
 */
export default class CandidateViewDto {
  /**
   * Constructs a new <code>CandidateViewDto</code>.
   * @alias module:model/CandidateViewDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateViewDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateViewDto} obj Optional instance to populate.
   * @return {module:model/CandidateViewDto} The populated <code>CandidateViewDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateViewDto();
      if (data.hasOwnProperty('fullName'))
        obj.fullName = ApiClient.convertToType(data['fullName'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('contactNumber'))
        obj.contactNumber = ApiClient.convertToType(data['contactNumber'], 'String');
      if (data.hasOwnProperty('slug'))
        obj.slug = ApiClient.convertToType(data['slug'], 'String');
      if (data.hasOwnProperty('employmentStatus'))
        obj.employmentStatus = EmploymentStatusType.constructFromObject(data['employmentStatus']);
      if (data.hasOwnProperty('dateOfBirth'))
        obj.dateOfBirth = ApiClient.convertToType(data['dateOfBirth'], 'Date');
      if (data.hasOwnProperty('gender'))
        obj.gender = GenderType.constructFromObject(data['gender']);
      if (data.hasOwnProperty('totalExperienceInYears'))
        obj.totalExperienceInYears = ApiClient.convertToType(data['totalExperienceInYears'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} fullName
 */
CandidateViewDto.prototype.fullName = undefined;

/**
 * @member {String} email
 */
CandidateViewDto.prototype.email = undefined;

/**
 * @member {String} contactNumber
 */
CandidateViewDto.prototype.contactNumber = undefined;

/**
 * @member {String} slug
 */
CandidateViewDto.prototype.slug = undefined;

/**
 * @member {module:model/EmploymentStatusType} employmentStatus
 */
CandidateViewDto.prototype.employmentStatus = undefined;

/**
 * @member {Date} dateOfBirth
 */
CandidateViewDto.prototype.dateOfBirth = undefined;

/**
 * @member {module:model/GenderType} gender
 */
CandidateViewDto.prototype.gender = undefined;

/**
 * @member {Number} totalExperienceInYears
 */
CandidateViewDto.prototype.totalExperienceInYears = undefined;

