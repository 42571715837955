/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The Overview model module.
 * @module model/Overview
 * @version v3.17(PROD)
 */
export default class Overview {
  /**
   * Constructs a new <code>Overview</code>.
   * @alias module:model/Overview
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Overview</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Overview} obj Optional instance to populate.
   * @return {module:model/Overview} The populated <code>Overview</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Overview();
      if (data.hasOwnProperty('totalYearsOfExperience'))
        obj.totalYearsOfExperience = ApiClient.convertToType(data['totalYearsOfExperience'], 'String');
      if (data.hasOwnProperty('summary'))
        obj.summary = ApiClient.convertToType(data['summary'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} totalYearsOfExperience
 */
Overview.prototype.totalYearsOfExperience = undefined;

/**
 * @member {String} summary
 */
Overview.prototype.summary = undefined;

