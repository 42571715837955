/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.17(PROD)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.54
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The AddressCreateDto model module.
 * @module model/AddressCreateDto
 * @version v3.17(PROD)
 */
export default class AddressCreateDto {
  /**
   * Constructs a new <code>AddressCreateDto</code>.
   * @alias module:model/AddressCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AddressCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AddressCreateDto} obj Optional instance to populate.
   * @return {module:model/AddressCreateDto} The populated <code>AddressCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AddressCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('createdBy'))
        obj.createdBy = ApiClient.convertToType(data['createdBy'], 'Number');
      if (data.hasOwnProperty('modifiedBy'))
        obj.modifiedBy = ApiClient.convertToType(data['modifiedBy'], 'Number');
      if (data.hasOwnProperty('street'))
        obj.street = ApiClient.convertToType(data['street'], 'String');
      if (data.hasOwnProperty('cityName'))
        obj.cityName = ApiClient.convertToType(data['cityName'], 'String');
      if (data.hasOwnProperty('zipCode'))
        obj.zipCode = ApiClient.convertToType(data['zipCode'], 'String');
      if (data.hasOwnProperty('formattedAddress'))
        obj.formattedAddress = ApiClient.convertToType(data['formattedAddress'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('countryId'))
        obj.countryId = ApiClient.convertToType(data['countryId'], 'Number');
      if (data.hasOwnProperty('stateId'))
        obj.stateId = ApiClient.convertToType(data['stateId'], 'Number');
      if (data.hasOwnProperty('cityId'))
        obj.cityId = ApiClient.convertToType(data['cityId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
AddressCreateDto.prototype.id = undefined;

/**
 * @member {Number} createdBy
 */
AddressCreateDto.prototype.createdBy = undefined;

/**
 * @member {Number} modifiedBy
 */
AddressCreateDto.prototype.modifiedBy = undefined;

/**
 * @member {String} street
 */
AddressCreateDto.prototype.street = undefined;

/**
 * @member {String} cityName
 */
AddressCreateDto.prototype.cityName = undefined;

/**
 * @member {String} zipCode
 */
AddressCreateDto.prototype.zipCode = undefined;

/**
 * @member {String} formattedAddress
 */
AddressCreateDto.prototype.formattedAddress = undefined;

/**
 * @member {String} type
 */
AddressCreateDto.prototype.type = undefined;

/**
 * @member {Number} countryId
 */
AddressCreateDto.prototype.countryId = undefined;

/**
 * @member {Number} stateId
 */
AddressCreateDto.prototype.stateId = undefined;

/**
 * @member {Number} cityId
 */
AddressCreateDto.prototype.cityId = undefined;

